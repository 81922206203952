import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import wood from "./assets/Images/wood.png";
import crt from "./assets/Images/caretRT.png";
import Button2 from "../Shared/Button2";

export class AboutPack extends Component {
  render() {
    return (
      <section className="__pack-about-bg">
        <Container fluid className="__pdTop">
          <Row>
            <Col md={6}>
              <div style={{ position: "relative" }}>
                <img src={wood} alt="wood" className="__about-pack-img" />
                <p className="actualImg">ACTUAL SITE IMAGE</p>
              </div>
            </Col>
            <Col md={6} className="__divPosition">
              <div className="__aboutus">
                <h1 className="__aboutText">ABOUT US</h1>
              </div>
              <div className="__pt65">
                <h2 className="__JCPtitle">JAYANT CARGO PACKERS</h2>
              </div>
              <div className="__AboutPara">
                <p>
                  Jayant Cargo Packers was Established in 1966 as Suppliers and
                  Packers. Since then, We have Grown in Expertise and Pioneer in
                  Palletization of Export Cargo with Our Strong Management and
                  Highly Skilled Staff. We are certified ISPM - 15 by heat
                  Treatment. Exporters Have Used Our Pallets to send Cargo To
                  Australia, New Zealand, U.S.A and Many More Countries around
                  the World.
                </p>
              </div>
              <Button2
                title="Continue Reading"
                btnCSS="buttonAbout"
                alt="icon"
                img={crt}
                iconDes="__crtIconAbout"
                whereto="/jcp/abouts"
              />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default AboutPack;
