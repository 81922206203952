import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import lt from "./assets/Images/arrowlt.png";
import rt from "./assets/Images/arrowrt.png";
import g1 from "./assets/Images/g1.png";
import g2 from "./assets/Images/g2.png";
import g3 from "./assets/Images/g3.png";
import eye from "./assets/Images/eye.png";
// import { Link } from "react-router-dom";

export class GalleryPack extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="__Gallerysection __ptTop __tabdisableMobile">
          <Container fluid className="__fluidCon">
            <Row>
              <Col md={12}>
                <p className="__GalleryPackHead"></p>
              </Col>
              <Col md={12}>
                <Container fluid className="__insideJCP">
                  <Row>
                    <Col md={6} className="__after __cusCol30">
                      <p className="__Inside">INSIDE JCP</p>
                    </Col>
                    <Col md={6} className="__textEnd __cusCol70">
                      <button
                        className="buttonGallery"
                        href="#carouselExampleIndicators"
                        data-slide="prev"
                      >
                        <img src={lt} className="__arrowimg" alt="" />
                      </button>
                      <button
                        className="buttonGallery"
                        href="#carouselExampleIndicators"
                        data-slide="next"
                      >
                        <img src={rt} className="__arrowimg" alt="" />
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>

          <Container fluid className="___carosel __fluidCon">
            <Row>
              <Col md={12}>
                <div id="carouselExampleIndicators" className="carousel slide">
                  <div className="carousel-inner">
                    {/* Slider 1 */}
                    <div className="carousel-item active">
                      <Container fluid className="__fluidCon">
                        <Row>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g1} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g2} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g3} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g1} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    {/* Slider 2 */}
                    <div className="carousel-item">
                      <Container fluid className="__fluidCon">
                        <Row>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g1} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g2} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g3} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g1} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    {/* Slider */}
                    <div className="carousel-item">
                      <Container fluid className="__fluidCon">
                        <Row>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g1} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g2} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g3} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                          <Col md={3} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g1} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__tabSingle __tabdisabledesktop">
          <Container fluid className="__fluidCon">
            <Row>
              <Col md={12}>
                <p className="__GalleryPackHead"></p>
              </Col>
              <Col md={12}>
                <Container fluid className="__insideJCP">
                  <Row>
                    <Col md={6} className="__after __cusCol30">
                      <p className="__Inside">INSIDE JCP</p>
                    </Col>
                    <Col md={6} className="__textEnd __cusCol70">
                      <button
                        className="buttonGallery"
                        href="#carouselExampleIndicatorsMob"
                        data-slide="prev"
                      >
                        <img src={lt} className="__arrowimg" alt="" />
                      </button>
                      <button
                        className="buttonGallery"
                        href="#carouselExampleIndicatorsMob"
                        data-slide="next"
                      >
                        <img src={rt} className="__arrowimg" alt="" />
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>

          <Container fluid className="___carosel __fluidCon">
            <Row>
              <Col md={12}>
                <div
                  id="carouselExampleIndicatorsMob"
                  className="carousel slide"
                >
                  <div className="carousel-inner">
                    {/* Slider 1 */}
                    <div className="carousel-item active">
                      <Container fluid className="__fluidCon">
                        <Row>
                          <Col md={12} className="__galitems">
                            <a href="/jcp/gallery">
                              <img src={g1} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    {/* Slider 2 */}
                    <div className="carousel-item">
                      <Container fluid className="__fluidCon">
                        <Row>
                          <Col md={12} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g2} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    {/* Slider */}
                    <div className="carousel-item">
                      <Container fluid className="__fluidCon">
                        <Row>
                          <Col md={12} className="__galitem">
                            <a href="/jcp/gallery">
                              <img src={g3} className="__sliderImgs" alt="" />
                              <img src={eye} className="__eye" alt="" />
                              <span className="__vieweye">view all</span>
                            </a>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default GalleryPack;
