import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

// import prev from "../assets/Images/prev.png";
// import next from "../assets/Images/next.png";
// import s1 from "../assets/Images/strapMain.png";
import drum from "../assets/Images/palletization/drum.png";
import carton from "../assets/Images/palletization/carton.png";
import bag from "../assets/Images/palletization/bag.png";
import jb from "../assets/Images/jb.png";
import dp from "../assets/Images/dp.png";

export class Palletization extends Component {
  componentDidMount() {
    document.title = " Palletization of cargo | Palletization Services | JCP";
  }
  render() {
    return (
      <section className="__mtbot">
        <Container fluid className="__productPos">
          <Row>
            <Col md={12}>
              <div className="__productNamePalletization"></div>
            </Col>
            <Col md={12} className=" __cros">
              <Container fluid className="__mTop">
                <Row>
                  <Col md={1} className="__endPrev"></Col>
                  <Col md={10}>
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <nav className="__navProduct __cusProduct">
                            <div
                              className="nav nav-tabs __nav-tab navtab"
                              id="nav-tab"
                              role="tablist"
                            >
                              <a
                                className="nav-item nav-link __nav-link navlink active"
                                id="nav-pallets-tab"
                                data-toggle="tab"
                                href="#nav-pallets"
                                role="tab"
                                aria-controls="nav-pallets"
                                aria-selected="true"
                              >
                                DRUMS CARGO
                              </a>
                              <a
                                className="nav-item nav-link __nav-link navlink"
                                id="nav-PALLETIZATION-tab"
                                data-toggle="tab"
                                href="#nav-PALLETIZATION"
                                role="tab"
                                aria-controls="nav-PALLETIZATION"
                                aria-selected="false"
                              >
                                BAGS CARGO
                              </a>
                              <a
                                className="nav-item nav-link __nav-link navlink"
                                id="nav-lc-tab"
                                data-toggle="tab"
                                href="#nav-lc"
                                role="tab"
                                aria-controls="nav-lc"
                                aria-selected="false"
                              >
                                CARTONS CARGO
                              </a>
                              <a
                                className="nav-item nav-link __nav-link navlink"
                                id="nav-jb-tab"
                                data-toggle="tab"
                                href="#nav-jb"
                                role="tab"
                                aria-controls="nav-jb"
                                aria-selected="false"
                              >
                                JUMBO BAG CARGO
                              </a>
                            </div>
                          </nav>
                        </div>
                        {/* Paste ur code here */}
                      </div>
                    </div>
                  </Col>
                  <Col md={1} className="__endNext"></Col>
                </Row>
              </Container>
            </Col>
            <Col md={12} className="__pad">
              <div className="tab-content" id="nav-tabContent">
                {/* ONE */}
                <div
                  className="tab-pane fade show active __drumBg __mtTop40"
                  id="nav-pallets"
                  role="tabpanel"
                  aria-labelledby="nav-pallets-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={drum} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">
                            Palletization Of Drums Cargo
                          </h3>
                        </div>
                        <div>
                          <p className="__details">
                            We provide our Esteemed Clients with Drum Pallets
                            made from Superior Quality Raw Material, which are
                            used in the Palletization of Drums Cargo. These
                            Pallets are strong, durable and safe to use and is
                            used in many Industrial Applications.
                          </p>
                          <p className="__details">
                            Drum Palletizing Makes Moving Drums Easy.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* TWO */}
                <div
                  className="tab-pane fade __bagBg  __mtTop40"
                  id="nav-PALLETIZATION"
                  role="tabpanel"
                  aria-labelledby="nav-PALLETIZATION-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={bag} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">
                            Palletization Of Bags Cargo
                          </h3>
                        </div>
                        <div>
                          <p className="__details">
                            Bag Cargo Palletization is Built for Repetitive
                            Heavy Lifting and Moving Cargo at Faster Pace, With
                            Minimum Labor and Saves on Pilferage, Minimizes
                            Injury to labour and makes Loading of Shipment Easy.
                          </p>
                          {/* <p className="__details">
                            Bag palletizers are designed to stack bagged product
                            onto pallets for storage and shipment. These
                            machines are used widely by companies of all sizes
                            who desire to minimize the risk of employee injury
                            from repetitious manual stacking of bags or who wish
                            to increase production without increasing labor
                            costs.
                          </p> */}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* THREE */}
                <div
                  className="tab-pane fade __cartonBg __mtTop40"
                  id="nav-lc"
                  role="tabpanel"
                  aria-labelledby="nav-lc-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={carton} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">
                            Palletization of Cartons Cargo
                          </h3>
                        </div>
                        <div>
                          <p className="__details">
                            {/* Carton Palletizers Are Used To Lift Cases Or Cartons
                            From Production Lines And Place Or Stack Them
                            Perfectly Onto A Pallet For Shipping, Moving Or
                            Storing. A Complete Load Can Be Stacked In A Number
                            Of Configurations, Such As Column-Stacked,
                            Interlocked Or Pin-Wheel Configurations. */}
                            Carton Palletizing is for case boxes or Cartons to
                            be placed on the Pallet for Shipping, Moving or
                            Storing. They can be stacked in Various
                            Configurations such as Column-stacked, Interlocked
                            or Pin-wheel Configurations.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* FOUR */}
                <div
                  className="tab-pane fade __jbBg __mtTop40"
                  id="nav-jb"
                  role="tabpanel"
                  aria-labelledby="nav-jb-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={jb} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">
                            Palletization of Jumbo Bag Cargo
                          </h3>
                        </div>
                        <div>
                          <p className="__details">
                            {/* Jumbo Bag Palletizing Systems Is Built For A
                            Flexible Intermediate Bulk Container (FIBC), Bulk
                            Bag, Super Sack, Or Big Bag, Is An Industrial
                            Container Made Of Flexible Fabric That Is Designed
                            For Storing And Transporting Dry, Flowable Products,
                            Such As Sand, Fertilizer, And Granules Of Plastic.
                            Capacity Of Storage Is 500 Kg 1000 kg & 1200 Kg
                            Colour Milky White With Various Options Of Lifting
                            Loops. */}
                            We are expert in Jumbo Bag Palletizing. This System
                            is built for Flexible Intermediate Bulk Container
                            called FIBC, Super Sack or Big Bag. This is an
                            Industrial Container bag made of Flexible Fabric
                            that is designed for Storing and Transporting dry,
                            flow-able Products, such as Sand, Fertilizer, and
                            Plastic Granules etc.
                          </p>
                          <p className="__details">
                            Storage capacity of these Milky White bags varies
                            from 500 kg to 1200kg and comes with various options
                            of lifting loops.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* ACCORDION STARTS */}
        <section className="__accordionForMobile disableInDesktop">
          <div className="__productNamePalletization"></div>
          <Container fluid className="cusFluidaccordian">
            <div className="accordion cusAccor" id="accordionExample">
              {/* FIRST */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingOne"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style={{ zIndex: "99" }}
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion"
                      type="button"
                    >
                      DRUMS CARGO
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={drum} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        We provide our Esteemed Clients with Drum Pallets made
                        from Superior Quality Raw Material, which are used in
                        the Palletization of Drums Cargo. These Pallets are
                        strong, durable and safe to use and is used in many
                        Industrial Applications.
                      </p>
                      <p className="__details">
                        Drum Palletizing Makes Moving Drums Easy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* SECOND */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingTwo"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      BAGS CARGO
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={bag} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        Bag Cargo Palletization is Built For Repetitive Heavy
                        Lifting and Moving Cargo at Faster Pace , With Minimum
                        Labor and Saves on Pilferage, Minimizes Injury to labour
                        and makes Loading of Shipment Easy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* THIRD*/}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingThree"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      CARTONS CARGO
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={carton} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        {/* Carton Palletizers Are Used To Lift Cases Or Cartons
                        From Production Lines And Place Or Stack Them Perfectly
                        Onto A Pallet For Shipping, Moving Or Storing. A
                        Complete Load Can Be Stacked In A Number Of
                        Configurations, Such As Column-Stacked, Interlocked Or
                        Pin-Wheel Configurations. */}
                        Carton Palletizing is for case boxes or Cartons to be
                        placed on the Pallet for Shipping, Moving or Storing.
                        They can be stacked in Various Configurations such as
                        Column-stacked, Interlocked or Pin-wheel Configurations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* FOURTH */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingFour"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      JUMBO BAG CARGO
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={jb} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        We are expert in Jumbo Bag Palletizing. This System is
                        built for Flexible Intermediate Bulk Container called
                        FIBC, Super Sack or Big Bag. This is an Industrial
                        Container bag made of Flexible Fabric that is designed
                        for Storing and Transporting dry, flow-able Products,
                        such as Sand, Fertilizer, and Plastic Granules etc.
                      </p>
                      <p className="__details">
                        Storage capacity of these Milky White bags varies from
                        500 kg to 1200kg and comes with various options of
                        lifting loops.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </section>
    );
  }
}

export default Palletization;
