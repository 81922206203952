import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import cp1 from "../assets/Images/pallets/cp1.png";
import cp2 from "../assets/Images/pallets/cp2.png";
import cp3 from "../assets/Images/pallets/cp3.png";
import cp4 from "../assets/Images/pallets/cp4.png";
import cp5 from "../assets/Images/pallets/cp5.png";
import cp6 from "../assets/Images/pallets/cp6.png";
import cp7 from "../assets/Images/pallets/cp7.png";
import cp8 from "../assets/Images/pallets/cp8.png";
import cp9 from "../assets/Images/pallets/cp9.png";
import ep1 from "../assets/Images/pallets/ep1.png";
import ep2 from "../assets/Images/pallets/ep2.png";
import ep3 from "../assets/Images/pallets/ep3.png";
import ep4 from "../assets/Images/pallets/ep4.png";
import gma from "../assets/Images/pallets/gma.png";
import plastic from "../assets/Images/pallets/plastic.png";
import p2 from "../assets/Images/pallets/p2.png";
import p3 from "../assets/Images/pallets/p3.png";
import p4 from "../assets/Images/pallets/p4.png";
import p5 from "../assets/Images/pallets/p5.png";
import lt from "../assets/Images/arrowlt.png";
import rt from "../assets/Images/arrowrt.png";

import dp from "../assets/Images/dp.png";

import { Link } from "react-router-dom";

export class Pallets extends Component {
  constructor(props) {
    super(props);
    this.handleSaveLink = this.handleSaveLink.bind(this);
  }

  handleSaveLink = (e) => {
    localStorage.setItem("1", e.target.id);
  };
  componentDidMount() {
    document.title = "Economical Wooden and Plastic Pallets | JCP";
  }
  render() {
    return (
      <section className="__mtbot">
        <Container fluid className="__productPos">
          <Row>
            <Col md={12}>
              <div className="__productNamePallet"></div>
            </Col>
            <Col md={12} className=" __cros">
              {/* <div className="__productNamePallet"></div> */}
              <Container fluid className="__mTop">
                <Row>
                  <Col md={1} className="__endPrev">
                    {/* <a href="#carouselExampleIndicators" data-slide="prev">
                      <img src={prev} alt="prev" className="__prevImg" />
                    </a> */}
                  </Col>
                  <Col md={10}>
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <nav className="__navProduct __cusProduct">
                            <div
                              className="nav nav-tabs __nav-tab navtab"
                              id="nav-tab"
                              role="tablist"
                            >
                              <a
                                className={`nav-item nav-link __nav-link navlink ${
                                  localStorage.getItem("1") ===
                                  "nav-pallets-tab"
                                    ? "show active"
                                    : ""
                                } `}
                                id="nav-pallets-tab"
                                data-toggle="tab"
                                href="#nav-pallets"
                                role="tab"
                                aria-controls="nav-pallets"
                                aria-selected="true"
                                onClick={this.handleSaveLink}
                              >
                                CP PALLETS
                              </a>
                              <a
                                className={`nav-item nav-link __nav-link navlink ${
                                  localStorage.getItem("1") ===
                                  "nav-PALLETIZATION-tab"
                                    ? "show active"
                                    : ""
                                } `}
                                id="nav-PALLETIZATION-tab"
                                data-toggle="tab"
                                href="#nav-PALLETIZATION"
                                role="tab"
                                aria-controls="nav-PALLETIZATION"
                                aria-selected="false"
                                onClick={this.handleSaveLink}
                              >
                                EPAL PALLETS
                              </a>
                              <a
                                className={`nav-item nav-link __nav-link navlink ${
                                  localStorage.getItem("1") === "nav-lc-tab"
                                    ? "show active"
                                    : ""
                                } `}
                                id="nav-lc-tab"
                                data-toggle="tab"
                                href="#nav-lc"
                                role="tab"
                                aria-controls="nav-lc"
                                aria-selected="false"
                                onClick={this.handleSaveLink}
                              >
                                GMA PALLETS
                              </a>
                              <a
                                className={`nav-item nav-link __nav-link navlink ${
                                  localStorage.getItem("1") ===
                                  "nav-solution-tab"
                                    ? "show active"
                                    : ""
                                } `}
                                id="nav-solution-tab"
                                data-toggle="tab"
                                href="#nav-solution"
                                role="tab"
                                aria-controls="nav-solution"
                                aria-selected="false"
                                onClick={this.handleSaveLink}
                              >
                                PLASTIC PALLETS
                              </a>
                            </div>
                          </nav>
                        </div>
                        {/* Paste ur code here */}
                      </div>
                    </div>
                  </Col>
                  <Col md={1} className="__endNext">
                    {/* <a href="#carouselExampleIndicators" data-slide="next">
                      <img src={next} alt="next" className="__nextImg" />
                    </a> */}
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={12} className="__pad">
              <div className="tab-content" id="nav-tabContent">
                {/* ONE */}
                <div
                  className={`tab-pane fade __mtTop40 ${
                    localStorage.getItem("1") === "nav-pallets-tab"
                      ? "show active"
                      : ""
                  } `}
                  id="nav-pallets"
                  role="tabpanel"
                  aria-labelledby="nav-pallets-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={12} className="__cpPara">
                        <p>
                          The Chemical Industry has{" "}
                          <span className="bold">Standardized</span> the Pallets
                          into nine different Types.{" "}
                          <span className="bold">Chemical Pallet (CP)</span> can
                          be internally re-used or returned for re-use.
                        </p>
                        <p>
                          <span className="bold">CP 1 to 5</span> are{" "}
                          <span className="bold">Pallets</span> with skids and
                          are majorly used in Chemical Industries.
                        </p>
                        <h3>Reasons to use CP 1-5 Pallets are:</h3>
                        <ul>
                          {/* <li>they are cheaper than frame pallets</li>
                          <li>repairs are easier than with frame pallets</li>
                          <li>they are sufficiently stable</li>
                          <li>
                            easy for users of manual lift trucks to manage
                          </li> */}
                          <li>Economical than Frame Pallets</li>
                          <li>Easier to Repair and Re-use</li>
                          <li>Sufficiently stable </li>
                          <li>Easy to relocate with hand truck or Fork Lift</li>
                        </ul>
                        <p>
                          CP 6 to 9 are Pallets with two levels Frames or
                          Windows and that makes it more stable
                        </p>
                      </Col>
                    </Row>
                    <Row className="pb-3 pt-3">
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/cp1" exact>
                          {" "}
                          <img
                            src={cp1}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            Chemical Pallet CP 1 <br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/cp2" exact>
                          {" "}
                          <img
                            src={cp2}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            Chemical Pallet CP 2 <br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/cp3" exact>
                          {" "}
                          <img
                            src={cp3}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            Chemical Pallet CP 3<br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/cp4" exact>
                          {" "}
                          <img
                            src={cp4}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            Chemical Pallet CP 4
                            <br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                    </Row>
                    <Row className="pb-3 pt-3">
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/cp5" exact>
                          {" "}
                          <img
                            src={cp5}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            Chemical Pallet CP 5<br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/cp6" exact>
                          {" "}
                          <img
                            src={cp6}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            Chemical Pallet CP 6<br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/cp7" exact>
                          {" "}
                          <img
                            src={cp7}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            Chemical Pallet CP 7 <br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/cp8" exact>
                          {" "}
                          <img
                            src={cp8}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            Chemical Pallet CP 8<br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>{" "}
                    </Row>
                    <Row className="pb-2 pt-3">
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/cp9" exact>
                          {" "}
                          <img
                            src={cp9}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            Chemical Pallet CP 9<br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* TWO */}
                <div
                  className={`tab-pane fade __mtTop40 ${
                    localStorage.getItem("1") === "nav-PALLETIZATION-tab"
                      ? "show active"
                      : ""
                  } `}
                  id="nav-PALLETIZATION"
                  role="tabpanel"
                  aria-labelledby="nav-PALLETIZATION-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={12} className="__cpPara">
                        <p>
                          Durable, Environmentally Sustainable and Versatile
                          usage makes perfect description of{" "}
                          <span className="bold">
                            EPAL 1 EURO Pallets. Wooden Euro Pallets
                          </span>{" "}
                          are the most popular{" "}
                          <span className="bold">Pallets</span> for Shipping
                          Containers. They can be used as{" "}
                          <span className="bold">Exchangeable Pallets</span>.
                        </p>
                        <p>
                          <span className="bold">EPAL</span> Products meets
                          stringent Requirements of{" "}
                          <span className="bold">Pharmaceutical</span> and{" "}
                          <span className="bold">Food Industries</span> and can
                          be traded around the{" "}
                          <span className="bold">World</span>.
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/ep1" exact>
                          {" "}
                          <img
                            src={ep1}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            EPAL 1 Pallet <br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/ep2" exact>
                          {" "}
                          <img
                            src={ep2}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            EPAL 2 Pallet <br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/ep3" exact>
                          {" "}
                          <img
                            src={ep3}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            EPAL 3 Pallet <br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                      <Col md={3} className="__prditem">
                        {" "}
                        <Link to="/jcp/pallet/ep4" exact>
                          {" "}
                          <img
                            src={ep4}
                            alt="s1"
                            className="__st __b1solid"
                          />{" "}
                          <span className="__prdtitle">
                            EPAL 6 Pallet <br />
                            Click from more details
                          </span>
                        </Link>{" "}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* THREE */}
                <div
                  className={`tab-pane fade __gmaBg __mtTop40 ${
                    localStorage.getItem("1") === "nav-lc-tab"
                      ? "show active"
                      : ""
                  } `}
                  id="nav-lc"
                  role="tabpanel"
                  aria-labelledby="nav-lc-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={gma} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">GMA PALLETS</h3>
                        </div>
                        <div>
                          <p className="__details">
                            {/* <span className="bold">
                              GMA Wood Stringer Pallet
                            </span>{" "}
                            (48 x 40 inches) is for{" "}
                            <span className="bold">North American Market</span>.
                            Nominal 4” or 6” Lead Boards are on Top and Bottom
                            Decks. Stringers Have No Block or Companion Board
                            pairs. It may Include Plate And /Or Corrugate Pairs.
                            Most 48" x 40" Pallets Are Manufactured as per{" "}
                            <span className="bold">
                              Grocery Manufacturer's Association
                            </span>{" "}
                            Standards, Or called{" "}
                            <span className="bold">GMA</span>, Style Pallets. */}
                            GMA Wood Stringer Pallet (48 x 40 inches) is for
                            North American Market. Nominal 4” or 6” Lead Boards
                            are on Top and Bottom Decks. Stringers have no Block
                            or Companion Board pairs. It may Include Plate and
                            /or Corrugate Pairs. Most 48&quot; x 40&quot;
                            Pallets are manufactured as per Grocery
                            Manufacturer&#39;s Association Standards, or called
                            GMA, Style Pallets.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* FOUR */}
                <div
                  className={`tab-pane fade __plasticBg __mtTop40 ${
                    localStorage.getItem("1") === "nav-solution-tab"
                      ? "show active"
                      : ""
                  } `}
                  id="nav-solution"
                  role="tabpanel"
                  aria-labelledby="nav-solution-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          {/* <img src={p5} alt="s1" className="__st" /> */}
                          <div
                            id="carouselExampleControlspalastic1"
                            className="carousel slide"
                            data-ride="carousel"
                          >
                            <div className="carousel-inner">
                              <div className="carousel-item centerAlign active">
                                {" "}
                                <img src={p2} alt="s1" className="__st " />{" "}
                              </div>
                              <div className="carousel-item centerAlign">
                                {" "}
                                <img src={p3} alt="s1" className="__st " />{" "}
                              </div>
                              <div className="carousel-item centerAlign">
                                {" "}
                                <img src={p4} alt="s1" className="__st " />{" "}
                              </div>
                              <div className="carousel-item centerAlign">
                                {" "}
                                <img src={p5} alt="s1" className="__st " />{" "}
                              </div>
                            </div>
                            <div className="navigateBtn2">
                              <button
                                className="buttonGallery"
                                href="#carouselExampleControlspalastic1"
                                data-slide="prev"
                              >
                                <img src={lt} className="__arrowimg" alt="" />
                              </button>
                              <button
                                className="buttonGallery"
                                href="#carouselExampleControlspalastic1"
                                data-slide="next"
                              >
                                <img src={rt} className="__arrowimg" alt="" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">PLASTIC PALLETS</h3>
                        </div>
                        <div>
                          <p className=" __details">
                            A Plastic Pallet offers attraction of being lighter
                            in Weight and Impervious to Moisture.
                          </p>
                          <p className="__details">
                            For Hygiene - Sensitive Applications such as
                            Pharmaceutical Manufacturing and Food Production,
                            Plastic Pallets are sometimes preferred over Timber
                            because of its Non-Porous, Non-Odor Absorption, and
                            Easily Cleaned surfaces.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* ACCORDION STARTS */}
        <section className="__accordionForMobile disableInDesktop">
          <div className="__productNamePallet"></div>
          <Container fluid className="cusFluidaccordian">
            <div className="accordion cusAccor" id="accordionExample">
              {/* FIRST */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingOne"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style={{ zIndex: "99" }}
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion"
                      type="button"
                    >
                      CP PALLETS
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body forPara">
                    <Container fluid>
                      <Row>
                        <Col md={12} className="__cpPara">
                          <p>
                            The Chemical Industry has{" "}
                            <span className="bold">Standardized</span> the
                            Pallets into nine different Types.{" "}
                            <span className="bold">Chemical Pallet (CP)</span>{" "}
                            can be internally re-used or returned for re-use.
                          </p>
                          <p>
                            <span className="bold">CP 1 to 5</span> are{" "}
                            <span className="bold">Pallets</span> with skids and
                            are majorly used in Chemical Industries.
                          </p>

                          <ul>
                            <li>Economical than Frame Pallets</li>
                            <li>Easier to Repair and Re-use</li>
                            <li>Sufficiently stable </li>
                            <li>
                              Easy to relocate with hand truck or Fork Lift
                            </li>
                          </ul>
                          <p>
                            CP 6 to 9 are Pallets with two levels Frames or
                            Windows and that makes it more stable
                          </p>
                        </Col>
                      </Row>
                    </Container>
                    <Container fluid className="__pad">
                      <div
                        id="carouselExampleControlspal1"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner">
                          <div className="carousel-item centerAlign active">
                            <Link to="/jcp/pallet/cp1" exact>
                              {" "}
                              <img src={cp1} alt="s1" className="__st " />{" "}
                              <h3 className="textAccordion">
                                CHEMICAL PALLET 1
                              </h3>
                            </Link>{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            <Link to="/jcp/pallet/cp2" exact>
                              {" "}
                              <img src={cp2} alt="s1" className="__st " />{" "}
                              <h3 className="textAccordion">
                                CHEMICAL PALLET 2
                              </h3>
                            </Link>{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            <Link to="/jcp/pallet/cp3" exact>
                              {" "}
                              <img src={cp3} alt="s1" className="__st " />{" "}
                              <h3 className="textAccordion">
                                CHEMICAL PALLET 3
                              </h3>
                            </Link>{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            <Link to="/jcp/pallet/cp4" exact>
                              {" "}
                              <img src={cp4} alt="s1" className="__st " />{" "}
                              <h3 className="textAccordion">
                                CHEMICAL PALLET 4
                              </h3>
                            </Link>{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            <Link to="/jcp/pallet/cp5" exact>
                              {" "}
                              <img src={cp5} alt="s1" className="__st " />{" "}
                              <h3 className="textAccordion">
                                CHEMICAL PALLET 5
                              </h3>
                            </Link>{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            <Link to="/jcp/pallet/cp6" exact>
                              {" "}
                              <img src={cp6} alt="s1" className="__st " />{" "}
                              <h3 className="textAccordion">
                                CHEMICAL PALLET 6
                              </h3>
                            </Link>{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            <Link to="/jcp/pallet/cp7" exact>
                              {" "}
                              <img src={cp7} alt="s1" className="__st " />{" "}
                              <h3 className="textAccordion">
                                CHEMICAL PALLET 7
                              </h3>
                            </Link>{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            <Link to="/jcp/pallet/cp8" exact>
                              {" "}
                              <img src={cp8} alt="s1" className="__st " />{" "}
                              <h3 className="textAccordion">
                                CHEMICAL PALLET 8
                              </h3>
                            </Link>{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            <Link to="/jcp/pallet/cp9" exact>
                              {" "}
                              <img src={cp9} alt="s1" className="__st " />{" "}
                              <h3 className="textAccordion">
                                CHEMICAL PALLET 9
                              </h3>
                            </Link>{" "}
                          </div>
                        </div>
                        <a
                          className="carousel-control-prev"
                          href="#carouselExampleControlspal1"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next"
                          href="#carouselExampleControlspal1"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
              {/* SECOND */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingTwo"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      EPAL PALLETS
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body forPara">
                    <Container fluid>
                      <Row>
                        <Col md={12} className="__cpPara">
                          <p>
                            Durable, Environmentally Sustainable and Versatile
                            usage makes perfect description of{" "}
                            <span className="bold">
                              EPAL 1 EURO Pallets. Wooden Euro Pallets
                            </span>{" "}
                            are the most popular{" "}
                            <span className="bold">Pallets</span> for Shipping
                            Containers. They can be used as{" "}
                            <span className="bold">Exchangeable Pallets</span>.
                          </p>
                          <p>
                            <span className="bold">EPAL</span> Products meets
                            stringent Requirements of
                            <span className="bold">
                              Pharmaceutical
                            </span> and{" "}
                            <span className="bold">Food Industries</span> and
                            can be traded around the{" "}
                            <span className="bold">World</span>.
                          </p>
                        </Col>
                      </Row>
                    </Container>
                    <Container fluid className="__pad">
                      <div
                        id="carouselExampleControlspal2"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner">
                          <div className="carousel-item centerAlign active">
                            <Link to="/jcp/pallet/ep1" exact>
                              {" "}
                              <img src={ep1} alt="s1" className="__st" />{" "}
                              <h3 className="textAccordion">EPAL PALLET 1</h3>
                            </Link>{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            <Link to="/jcp/pallet/ep2" exact>
                              {" "}
                              <img src={ep2} alt="s1" className="__st" />{" "}
                              <h3 className="textAccordion">EPAL PALLET 2</h3>
                            </Link>{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            <Link to="/jcp/pallet/ep3" exact>
                              {" "}
                              <img src={ep3} alt="s1" className="__st" />{" "}
                              <h3 className="textAccordion">EPAL PALLET 3</h3>
                            </Link>{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            <Link to="/jcp/pallet/ep4" exact>
                              {" "}
                              <img src={ep4} alt="s1" className="__st" />{" "}
                              <h3 className="textAccordion">EPAL PALLET 4</h3>
                            </Link>{" "}
                          </div>
                        </div>
                        <a
                          className="carousel-control-prev"
                          href="#carouselExampleControlspal2"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next"
                          href="#carouselExampleControlspal2"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
              {/* THIRD*/}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingThree"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      GMA PALLETS
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={gma} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        {/* <span className="bold">GMA Wood Stringer Pallet</span>{" "}
                        (48 x 40 inches) is for{" "}
                        <span className="bold">North American Market</span>.
                        Nominal 4” or 6” Lead Boards are on Top and Bottom
                        Decks. Stringers Have No Block or Companion Board pairs.
                        It may Include Plate And /Or Corrugate Pairs. Most 48" x
                        40" Pallets Are Manufactured as per{" "}
                        <span className="bold">
                          Grocery Manufacturer's Association
                        </span>{" "}
                        Standards, Or called <span className="bold">GMA</span>,
                        Style Pallets. */}
                        GMA Wood Stringer Pallet (48 x 40 inches) is for North
                        American Market. Nominal 4” or 6” Lead Boards are on Top
                        and Bottom Decks. Stringers have no Block or Companion
                        Board pairs. It may Include Plate and /or Corrugate
                        Pairs. Most 48&quot; x 40&quot; Pallets are manufactured
                        as per Grocery Manufacturer&#39;s Association Standards,
                        or called GMA, Style Pallets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* FOURTH*/}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingFour"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      PLASTIC PALLETS
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      {/* <img src={p2} alt="" /> */}
                      <div
                        id="carouselExampleControlspalasticMob1"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner">
                          <div className="carousel-item centerAlign active">
                            {" "}
                            <img src={p2} alt="s1" className="__st " />{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            {" "}
                            <img src={p3} alt="s1" className="__st " />{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            {" "}
                            <img src={p4} alt="s1" className="__st " />{" "}
                          </div>
                          <div className="carousel-item centerAlign">
                            {" "}
                            <img src={p5} alt="s1" className="__st " />{" "}
                          </div>
                        </div>
                        <div className="navigateBtn2">
                          <button
                            className="buttonGallery"
                            href="#carouselExampleControlspalasticMob1"
                            data-slide="prev"
                          >
                            <img src={lt} className="__arrowimg" alt="" />
                          </button>
                          <button
                            className="buttonGallery"
                            href="#carouselExampleControlspalasticMob1"
                            data-slide="next"
                          >
                            <img src={rt} className="__arrowimg" alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="accordionPara">
                      <p className=" __details">
                        A Plastic Pallet Offers The Attraction Of Being Lighter
                        In Weight And Impervious To Moisture.
                      </p>
                      <p className="__details">
                        For Hygiene-Sensitive Applications Such As
                        Pharmaceutical Manufacturing And Food Production,
                        Plastic Pallets Are Sometimes Preferred Over Timber
                        Because Of Their Non-Porous Surface, Non-Odour
                        Absorption, And Because They Are More Easily Cleaned.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </section>
    );
  }
}

export default Pallets;
