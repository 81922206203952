import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../Shared/Header";
import PackFooter from "../PackFooter";
import StrapTools from "./StrapTools";
import Pallets from "./Pallets";
import Palletization from "./Palletization";
import Lashing from "./Lashing";
import Box from "./Box";

export class MainProduct extends Component {
  render() {
    let CurrentPath = window.location.pathname;
    let strap;
    if (CurrentPath === "/jcp/strap") {
      strap = <StrapTools />;
    }
    if (CurrentPath === "/jcp/pallet") {
      strap = <Pallets />;
    }
    if (CurrentPath === "/jcp/palletization") {
      strap = <Palletization />;
    }
    if (CurrentPath === "/jcp/lashing") {
      strap = <Lashing />;
    }
    if (CurrentPath === "/jcp/box") {
      strap = <Box />;
    }
    return (
      <React.Fragment>
        <Header />
        <section className="__productBg">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead">Our Products</h1>
              </Col>
            </Row>
          </Container>
        </section>
        {strap}
        <PackFooter />
      </React.Fragment>
    );
  }
}

export default MainProduct;
