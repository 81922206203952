import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../Shared/Header";
import PackFooter from "../PackFooter";
import Button from "../../Shared/Button";

// Images
import cp1 from "../assets/Images/pallets/cp1in.png";
import cp2 from "../assets/Images/pallets/cp2in.png";
import cp3 from "../assets/Images/pallets/cp3in.png";
import cp4 from "../assets/Images/pallets/cp4in.png";
import cp5 from "../assets/Images/pallets/cp5in.png";
import cp6 from "../assets/Images/pallets/cp6in.png";
import cp7 from "../assets/Images/pallets/cp7in.png";
import cp8 from "../assets/Images/pallets/cp8in.png";
import cp9 from "../assets/Images/pallets/cp9in.png";
import ep1 from "../assets/Images/pallets/ep1.png";
import ep2 from "../assets/Images/pallets/ep2in.png";
import ep3 from "../assets/Images/pallets/ep3in.png";
import ep4 from "../assets/Images/pallets/ep4in.png";
import lt from "../assets/Images/arrowlt.png";

export class Cp1 extends Component {
  componentDidMount() {
    document.title = "CP Pallet | Chemical Pallet 1 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">
                  Chemical Pallet 1
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={cp1} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
                <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">1000 x 1200 x 138 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">23 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">Loads</td>
                      <td className="dis">please ask for details</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Application</td>
                      <td className="dis">multiple</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">Access</td>
                      <td className="dis">4 options</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">Base support</td>
                      <td className="dis">3 skids</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">8</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col md={12} className="backButton">
                <Button
                  title="BACK"
                  btnCSS="button"
                  img={lt}
                  iconDes="backArrow"
                  goto="/jcp/pallet"
                />
              </Col>
            </Row> */}
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}
export class Cp2 extends Component {
  componentDidMount() {
    document.title = "CP Pallet | Chemical Pallet 2 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">
                  Chemical Pallet 2
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={cp2} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">800 x 1200 x 141 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">17.5 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">Loads</td>
                      <td className="dis">please ask for details</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Application</td>
                      <td className="dis">multiple</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">Access</td>
                      <td className="dis">4 options</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">Base support</td>
                      <td className="dis">3 skids</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">8</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">possible</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
            
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}
export class Cp3 extends Component {
  componentDidMount() {
    document.title = "CP Pallet | Chemical Pallet 3 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">
                  Chemical Pallet 3
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={cp3} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">1140 x 1140 x 138 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">23.5 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">Loads</td>
                      <td className="dis">please ask for details</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Application</td>
                      <td className="dis">multiple</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">Access</td>
                      <td className="dis">4 options</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">Base support</td>
                      <td className="dis">3 skids</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">8</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}
export class Cp4 extends Component {
  componentDidMount() {
    document.title = "CP Pallet | Chemical Pallet 4 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">
                  Chemical Pallet 4
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={cp4} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">1140 x 1140 x 138 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">23.5 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">Loads</td>
                      <td className="dis">please ask for details</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Application</td>
                      <td className="dis">multiple</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">Access</td>
                      <td className="dis">4 options</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">Base support</td>
                      <td className="dis">3 skids</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">8</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
            
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}
export class Cp5 extends Component {
  componentDidMount() {
    document.title = "CP Pallet | Chemical Pallet 5 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">
                  Chemical Pallet 5
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={cp5} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">760 x 1140 x 138 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">16.5 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">Loads</td>
                      <td className="dis">please ask for details</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Application</td>
                      <td className="dis">multiple</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">Access</td>
                      <td className="dis">4 options</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">Base support</td>
                      <td className="dis">3 skids</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">8</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
            
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}
export class Cp6 extends Component {
  componentDidMount() {
    document.title = "CP Pallet | Chemical Pallet 6 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">
                  Chemical Pallet 6
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={cp6} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">1000 x 1200 x 156 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">23.5 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">Loads</td>
                      <td className="dis">please ask for details</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Application</td>
                      <td className="dis">multiple</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">Access</td>
                      <td className="dis">4 options</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">Base support</td>
                      <td className="dis">Moving skids</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">8</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}
export class Cp7 extends Component {
  componentDidMount() {
    document.title = "CP Pallet | Chemical Pallet 7 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">
                  Chemical Pallet 7
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={cp7} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">1100 x 1300 x 156 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">23.5 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">Loads</td>
                      <td className="dis">please ask for details</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Application</td>
                      <td className="dis">multiple</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">Access</td>
                      <td className="dis">4 options</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">Base support</td>
                      <td className="dis">Moving skids</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">8</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}
export class Cp8 extends Component {
  componentDidMount() {
    document.title = "CP Pallet | Chemical Pallet 8 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">
                  Chemical Pallet 8
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={cp8} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">1140 x 1400 x 156 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">24 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">Loads</td>
                      <td className="dis">please ask for details</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Application</td>
                      <td className="dis">multiple</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">Access</td>
                      <td className="dis">4 options</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">Base support</td>
                      <td className="dis">Moving skids</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">8</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}
export class Cp9 extends Component {
  componentDidMount() {
    document.title = "CP Pallet | Chemical Pallet 9 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">
                  Chemical Pallet 9
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={cp9} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">1140 x 1140 x 156 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">25 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">Loads</td>
                      <td className="dis">please ask for details</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Application</td>
                      <td className="dis">multiple</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">Access</td>
                      <td className="dis">4 options</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">Base support</td>
                      <td className="dis">Moving skids</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">8</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}

// EURO PALLETS

export class Ep1 extends Component {
  componentDidMount() {
    document.title = "EPAL Euro Pallet 1 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">EPAL Pallet 1</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={ep1} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">800 x 1.200 x 144 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">28 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">
                        Maximum load capacity
                      </td>
                      <td className="dis">1.500 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Repairs</td>
                      <td className="dis">Possible</td>
                    </tr>

                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">EPAL</td>
                      <td className="dis">yes</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}

export class Ep2 extends Component {
  componentDidMount() {
    document.title = "EPAL Euro Pallet 2 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">EPAL Pallet 2</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={ep2} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">1.000 x 1.200 x 162 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">31 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">
                        Maximum load capacity
                      </td>
                      <td className="dis">1.500 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Repairs</td>
                      <td className="dis">Possible</td>
                    </tr>

                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">EPAL</td>
                      <td className="dis">yes</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}

export class Ep3 extends Component {
  componentDidMount() {
    document.title = "EPAL Euro Pallet 3 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">EPAL Pallet 3</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={ep3} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">1.000 x 1.200 x 144 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">35 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">
                        Maximum load capacity
                      </td>
                      <td className="dis">1.500 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Repairs</td>
                      <td className="dis">Possible</td>
                    </tr>

                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">EPAL</td>
                      <td className="dis">yes</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}

export class Ep4 extends Component {
  componentDidMount() {
    document.title = "EPAL Euro Pallet 4 | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead fontSizeforPallet">EPAL Pallet 6</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__cpcontentSection">
          <Container fluid>
            <Row>
              <Col md={5} className="cus-col-5" style={{ textAlign: "center" }}>
                <img src={ep4} alt="" className="__cpImgs" />
              </Col>
              <Col md={7} className="__cpPara cus-col-7">
              <Row>
                  <Col md={6}>
                    <h3>Product Specifications</h3>
                  </Col>
                  <Col md={6} className="backButton">
                    <Button
                      title="BACK"
                      btnCSS="button"
                      img={lt}
                      iconDes="backArrow"
                      goto="/jcp/pallet"
                    />
                  </Col>
                </Row>
                <div>
                  <table className="__cpTable">
                    <tr>
                      <td className="__serialNumber">1</td>
                      <td className="__productNameTable">Dimensions</td>
                      <td className="dis">600 x 800 x 144 mm</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">2</td>
                      <td className="__productNameTable">Weight</td>
                      <td className="dis">14 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">3</td>
                      <td className="__productNameTable">
                        Maximum load capacity
                      </td>
                      <td className="dis">750 kg</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">4</td>
                      <td className="__productNameTable">Material</td>
                      <td className="dis">wood</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">5</td>
                      <td className="__productNameTable">Repairs</td>
                      <td className="dis">Possible</td>
                    </tr>

                    <tr>
                      <td className="__serialNumber">6</td>
                      <td className="__productNameTable">EPAL</td>
                      <td className="dis">yes</td>
                    </tr>
                    <tr>
                      <td className="__serialNumber">7</td>
                      <td className="__productNameTable">IPPC</td>
                      <td className="dis">yes</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
            {/* <Col md={12} className="backButton">
              <Button
                title="BACK"
                btnCSS="button"
                img={lt}
                iconDes="backArrow"
                goto="/jcp/pallet"
              />
            </Col> */}
          </Container>
        </section>
        <PackFooter />
      </React.Fragment>
    );
  }
}
