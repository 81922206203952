import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import "./Header.css";

import brandLogo from "../assets/Images/logo.png";
import menu from "../assets/Images/square.png";
import dp from "../assets/Images/dp.png";

function Header() {
  const [isopen, isNotOpen] = useState(false);

  const [isopensub, isNotOpensub] = useState(false);

  function handleMenu() {
    isNotOpen(!isopen);
  }

  function handleSubMenu() {
    isNotOpensub(!isopensub);
  }
  return (
    <header>
      <Container className="dissableinmobile">
        <Row>
          <Col md={12}>
            <nav className="cus-nav">
              <ul className="cus-ul">
                <li>
                  <Link to="/jcp/abouts" exact>
                    ABOUT
                  </Link>
                </li>
                <li>
                  <NavDropdown title="BUSINESSES" id="collasible-nav-dropdown">
                    <NavDropdown.Item>
                      <Link to="/jcp/pack" exact>
                        Packaging Solution
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/jcp/furniture">
                      Furniture Solution
                    </NavDropdown.Item>
                  </NavDropdown>
                </li>
                <li>
                  <Link to="/jcp/gallery">GALLERY</Link>
                </li>
                <li>
                  <Link to="/jcp/contactus">CONTACT</Link>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
      <Container fluid className="diableindesktop">
        <Row>
          <Col md={4} className="cuscolmd4">
            <img src={brandLogo} alt="logo" className="logo-img" />
          </Col>
          <Col md={8} className="cuscolmd8">
            <div className="BurgerMenu">
            <span onClick={() => handleMenu()}>MENU</span>
            <img
              src={menu}
              alt="menu"
              className="menuImg"
              onClick={() => handleMenu()}
            />
            </div>
            
          </Col>
          <Col md={12} className={`mainHeadMob ${isopen ? "display" : ""}`}>
            <ul>
              <Link to="/jcp/abouts" exact>
                ABOUT
              </Link>
              <li
                className={`submenu ${isopensub ? "submenuopen" : ""}`}
                onClick={() => handleSubMenu()}
              >
                <a href="#">
                  BUSINESS <img src={dp} className="__dp" />
                </a>
                <ul className="submobul">
                  <Link to="/jcp/pack" exact>
                    Packaging Solution
                  </Link>
                  <Link to="/jcp/furniture" exact>
                    Furniture Solution
                  </Link>
                </ul>
              </li>
              <Link to="/jcp/gallery">GALLERY</Link>
              <Link to="/jcp/contactus">CONTACT</Link>
            </ul>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Header;
