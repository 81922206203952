import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import FurnitureFooter from "./FurnitureFooter";
import Header from "../Shared/Header";
// Images
import logo from "./assets/logo.png";
import f1 from "./assets/1.png";
import f2 from "./assets/2.png";
import f3 from "./assets/3.png";
import f4 from "./assets/4.png";
import f5 from "./assets/5.png";
import f6 from "./assets/6.png";
import f7 from "./assets/7.png";
import f8 from "./assets/8.png";
import v1 from "./assets/v1.mp4";
import crt from "./assets/caretRT.png";

// Shared
import Button2 from "../Shared/Button2";

class FurnitureMain extends React.Component {
  componentDidMount() {
    document.body.style.backgroundImage = `none`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";
    document.title = "One stop for all your furniture needs | Furniture solutions";
    window.addEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <React.Fragment>
        <section className="furnitureTopSectionBG videosection">
          <Header />
          <div class="video-overlay"></div>
          <div className="videoContainer">
            <video autoPlay muted loop id="video" className="v1">
              <source src={v1} type="video/mp4" />
            </video>
          </div>
          <Container className="contentOver">
            <Row>
              <Col md={6} className="mrBottom">
                <div className="text2020"></div>
                <div className="furniture">
                  <span className="furText">Furniture</span>
                  <br />
                  <span className="colText">Collection</span>
                </div>
                <div className="scrollDiv" style={{ marginTop: "40px" }}>
                  <Button2
                    title="Scroll Below"
                    btnCSS="__GetStarted cursor"
                    alt="icon"
                    img={crt}
                    iconDes="__crtGetStarted"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="marTopsec2">
          <Container>
            <Row className="maxWidthForQuality">
              <Col md={4} className="md6">          
                  <h3>
                    Custom <br />
                    Designs
                  </h3>    
                  <hr/>            
              </Col>
              <Col md={4} className="md6 mrTopDiv">
                  <h3>
                    Highly <br />
                    Skilled Team
                  </h3>
                  <hr/>  
              </Col>
              <Col md={4} className="md6 mrTopDiv">
                  <h3>
                    Competitive <br />
                    Quotations
                  </h3>
                  <hr/>  
              </Col>
            </Row>
          </Container>
        </section>
        <section className="productCollection">
          <Container style={{ marginTop: "40px" }} className="colMargin">
            <Row>
              <Col md={12}>
                <div className="collection">
                  <h1>Our Collection</h1>
                </div>
              </Col>
            </Row>
            {/* 1 */}
            <Row className="maxWidthForProduct">
              <Col md={4}>
                <img src={f1} alt="" className="productImg" />
              </Col>
              <Col md={8}>
                <div className="productDescription">
                  <h2>
                    <span>1.</span> Single Bed
                  </h2>
                  <div></div>
                </div>
              </Col>
            </Row>
            {/* IN MOBILE 1*/}
            <Row className="maxWidthForProductMobile">
              <Col md={12}>
                <div className="productDescription">
                  <h2>
                    <span>1.</span> Single Bed
                  </h2>
                  <div></div>
                </div>
              </Col>
              <Col md={12}>
                <img src={f1} alt="" className="productImg" />
              </Col>
            </Row>

            <hr />
            {/* 2 */}
            <Row className="maxWidthForProduct">
              <Col md={4}>
                <img src={f2} alt="" className="productImg" />
              </Col>
              <Col md={8}>
                <div className="productDescription">
                  <h2>
                    <span>2.</span> Double Bed
                  </h2>
                  <div></div>
                </div>
              </Col>
            </Row>
            {/* IN MOBILE 2*/}
            <Row className="maxWidthForProductMobile">
              <Col md={12}>
                <div className="productDescription">
                  <h2>
                    <span>2.</span> Double Bed
                  </h2>
                  <div></div>
                </div>
              </Col>
              <Col md={12}>
                <img src={f2} alt="" className="productImg" />
              </Col>
            </Row>
            <hr />
            {/* 3 */}
            <Row className="maxWidthForProduct">
              <Col md={4}>
                <img src={f3} alt="" className="productImg" />
              </Col>
              <Col md={8}>
                <div className="productDescription">
                  <h2>
                    <span>3.</span> Side Table / T.V Rack
                  </h2>
                  <div></div>
                </div>
              </Col>
            </Row>
            {/* IN MOBILE */}
            <Row className="maxWidthForProductMobile">
              <Col md={12}>
                <div className="productDescription">
                  <h2>
                    <span>3.</span> Side Table / T.V Rack
                  </h2>
                  <div></div>
                </div>
              </Col>
              <Col md={12}>
                <img src={f3} alt="" className="productImg" />
              </Col>
            </Row>
            <hr />
            {/* 4 */}
            <Row className="maxWidthForProduct">
              <Col md={4}>
                <img src={f4} alt="" className="productImg" />
              </Col>
              <Col md={8}>
                <div className="productDescription">
                  <h2>
                    <span>4.</span> Dinning Set
                  </h2>
                  <div></div>
                </div>
              </Col>
            </Row>
            {/* IN Mobile 4 */}
            <Row className="maxWidthForProductMobile">
              <Col md={12}>
                <div className="productDescription">
                  <h2>
                    <span>4.</span> Dinning Set
                  </h2>
                  <div></div>
                </div>
              </Col>
              <Col md={12}>
                <img src={f4} alt="" className="productImg" />
              </Col>
            </Row>
            <hr />
            {/* 5 */}
            <Row className="maxWidthForProduct">
              <Col md={4}>
                <img src={f5} alt="" className="productImg" />
              </Col>
              <Col md={8}>
                <div className="productDescription">
                  <h2>
                    <span>5.</span> Kitchen Platform
                  </h2>
                  <div></div>
                </div>
              </Col>
            </Row>
            {/* IN MOBILE 5 */}
            <Row className="maxWidthForProductMobile">
              <Col md={12}>
                <div className="productDescription">
                  <h2>
                    <span>5.</span> Kitchen Platform
                  </h2>
                  <div></div>
                </div>
              </Col>
              <Col md={12}>
                <img src={f5} alt="" className="productImg" />
              </Col>
            </Row>
            <hr />
            {/* 6 */}
            <Row className="maxWidthForProduct">
              <Col md={4}>
                <img src={f6} alt="" className="productImg" />
              </Col>
              <Col md={8}>
                <div className="productDescription">
                  <h2>
                    <span>6.</span> Cupboard
                  </h2>
                  <div></div>
                </div>
              </Col>
            </Row>
            {/* IN MOBILE 6 */}
            <Row className="maxWidthForProductMobile">
              <Col md={12}>
                <div className="productDescription">
                  <h2>
                    <span>6.</span> Cupboard
                  </h2>
                  <div></div>
                </div>
              </Col>
              <Col md={12}>
                <img src={f6} alt="" className="productImg" />
              </Col>
            </Row>
            <hr />
            {/* 7 */}
            <Row className="maxWidthForProduct">
              <Col md={4}>
                <img src={f7} alt="" className="productImg" />
              </Col>
              <Col md={8}>
                <div className="productDescription">
                  <h2>
                    <span>7.</span> Shoe Rack
                  </h2>
                  <div></div>
                </div>
              </Col>
            </Row>
            {/* IN MOBILE 7 */}
            <Row className="maxWidthForProductMobile">
              <Col md={12}>
                <div className="productDescription">
                  <h2>
                    <span>7.</span> Shoe Rack
                  </h2>
                  <div></div>
                </div>
              </Col>
              <Col md={12}>
                <img src={f7} alt="" className="productImg" />
              </Col>
            </Row>
            <hr />
            {/* 8 */}
            <Row className="maxWidthForProduct">
              <Col md={4}>
                <img src={f8} alt="" className="productImg" />
              </Col>
              <Col md={8}>
                <div className="productDescription">
                  <h2>
                    <span>8.</span> Entrance Teak Wood Panel Door
                  </h2>
                  <div></div>
                </div>
              </Col>
            </Row>
            {/* IN MOBILE 5 */}
            <Row className="maxWidthForProductMobile">
              <Col md={12}>
                <div className="productDescription">
                  <h2>
                    <span>8.</span> Entrance Teak Wood Panel Door
                  </h2>
                  <div></div>
                </div>
              </Col>
              <Col md={12}>
                <img src={f8} alt="" className="productImg" />
              </Col>
            </Row>
          </Container>
        </section>
        <FurnitureFooter />
      </React.Fragment>
    );
  }
}

export default FurnitureMain;
