import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";
import Clock from "../../Components/assets/Images/clock.png";
import Fb from "../../Components/assets/Images/facebook.png";
import Gplus from "../../Components/assets/Images/gplus.png";
import Yt from "../../Components/assets/Images/brand.png";
import Phone from "../../Components/assets/Images/phone.png";
import Whatsapp from "../../Components/assets/Images/whatsapp-white.png"

function Footer() {
  return (
    <React.Fragment>
      <div className="posrel">
        <div className="cus-container-footer">
          <Row className="justify-space-between">
            <div className="cus-col">
              <div className="btn btn-primary cus-btn-footer">
                <span className="ptr-10">
                  <img src={Clock} alt="clock" />
                </span>
                ( Mon to Sat ) 9am to 7pm
              </div>
            </div>
            <div className="cus-col-socio">
              <div className="ml-100">
                <span>
                  <img src={Fb} alt="Facebook" className="img-width" />
                </span>
                <span className="position-gplus">
                  <img src={Gplus} alt="Google+" className="img-width" />
                </span>
                <span className="position-youtube">
                  <img src={Yt} alt="YouTube" className="img-width" />
                </span>
              </div>
            </div>
            <div className="cus-col-ph">
              <div className="btn btn-primary cus-btn-footer ph">
                <span className="ptr-10">
                  <img src={Phone} alt="clock" />
                </span>
                <a href="tel:+91 9712980621 ">+91 9712980621</a>/
                <a href="tel:+91 2836221967 ">+91 2836-221967</a>
                {/* <a href="tel:+91 2836220431">+91 2836-220431</a> */}
              </div>
            </div>
            <div className="cus-col-ph">
              <div className="btn btn-primary cus-btn-footer ph">
                <span className="ptr-10">
                  <img src={Whatsapp} alt="whatsapp" />
                </span>
                <a href="https://api.whatsapp.com/send?phone=919324147930">+91 93241 47930</a>
              </div>
            </div>
          </Row>
        </div>
        <div className="line"></div>
      </div>
      <Container className="brandingDesktop">
        <Row>
          <Col md={12}>
            <div className="copyryt">
              Copyright &copy; All Rights reserved - Designed & Maintained by
              <strong style={{ paddingLeft: "5px" }}>
                <a href="http://www.beakmedia.com/" className="beak">
                  Beak Media
                </a>
              </strong>
            </div>
          </Col>
        </Row>
      </Container>

      <footer className="displayinmobile">
        <Container>
          <Row>
            <Col md={12} className="middle">
              <div className="btn btn-primary cus-btn-footer">
                <span className="ptr-10">
                  <img src={Clock} alt="clock" />
                </span>
                ( Mon to Sat ) 9am to 7pm
              </div>
            </Col>
            <Col md={12} className="middle">
              <div className="btn btn-primary cus-btn-footer ph">
                <span className="ptr-10">
                  <img src={Phone} alt="clock" />
                </span>
                <a href="tel:+91 9712980621">+91 9712980621</a>/
                <a href="tel:+91 2836221967 ">+91 2836-221967</a>
              </div>
            </Col>
            <Col md={12} className="middle">
              <div className="btn btn-primary cus-btn-footer ph">
                <span className="ptr-10">
                  <img src={Whatsapp} alt="whatsapp" />
                </span>
                <a href="https://api.whatsapp.com/send?phone=919324147930">+91 93241 47930</a>
              </div>
            </Col>
            <Col md={12} className="middle">
              <div className="">
                <span>
                  <img src={Fb} alt="Facebook" className="img-width" />
                </span>
                <span className="position-gplus">
                  <img src={Gplus} alt="Google+" className="img-width" />
                </span>
                <span className="position-youtube">
                  <img src={Yt} alt="YouTube" className="img-width" />
                </span>
              </div>
            </Col>
            <Col md={12}>
              <div className="copyryt">
                Copyright @ All Rights reserved - Designed & Maintained by
                <strong style={{ paddingLeft: "5px" }}>
                  <a href="http://www.beakmedia.com/" className="beak">
                    Beak Media
                  </a>
                </strong>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
