import React, { Component } from "react";

import "./style.css";
import AboutPack from "./AboutPack";
import ProductsPack from "./ProductsPack";
import GalleryPack from "./GalleryPack";
import PackFooter from "./PackFooter";
import Head from "./Header/Head";

export class MainPagePack extends Component {
  componentDidMount() {
    document.title = "JCP | Packaging";
  }
  render() {
    return (
      <React.Fragment>
        {/* <Packhead /> */}
        <Head />
        <AboutPack />
        <ProductsPack />
        <GalleryPack />
        <PackFooter />
      </React.Fragment>
    );
  }
}

export default MainPagePack;
