import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import call from "../assets/Images/call.png";
import mail from "../assets/Images/mail.png";
import clock from "../assets/Images/clock.png";
import fb from "../assets/Images/facebook.png";
import gp from "../assets/Images/goole.png";
import yt from "../assets/Images/yt.png";
import wa from "../assets/Images/whatsapp.png";

export class PackContactFooter extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="margintop contactFordesktop">
          <Container>
            <Row>
              <div className="__mainContactFooter">
                <Col md={12}>
                  <Col md={12} className="__contactMed __Container">
                    <Container className="">
                      <Row>
                        <Col md={2}>
                          <Row>
                            <Col md={12}>
                              <img
                                src={clock}
                                alt="clock"
                                className="__MediumImg"
                              />
                            </Col>
                            <Col md={12} className="__clockText">
                              <span>( Mon to Sat )</span>
                              <br />
                              <span> 9am to 6pm</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row>
                            <Col md={12}>
                              <img
                                src={mail}
                                alt="mail"
                                className="__MediumImg"
                              />
                            </Col>
                            <Col md={12} className="__MailText">
                              {/* <span>jaynatgim@gmail.com</span> */}
                              <a href="mailto:jaynatgim@gmail.com">
                                jayantgim@gmail.com
                              </a>
                              <br />
                              {/* <span>cargopackersgim@gmail.com</span> */}
                              <a href="mailto:cargopackersgim@gmail.com">
                                cargopackersgim@gmail.com
                              </a>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={3}>
                          <Row>
                            <Col md={12}>
                              <img
                                src={call}
                                alt="call"
                                className="__MediumImg"
                              />
                            </Col>
                            <Col md={12} className="__CallText">
                              <a href="tel: +91 9712980621" className="ph-no">
                                +91 9712980621
                              </a>
                              <br />
                              {/* <span>+91 2836 - 221967</span> */}
                              <a href="tel: +91 2836-221967" className="ph-no">
                                +91 2836 - 221967
                              </a>
                              <br />
                              {/* <span>+91 2836 - 220431</span> */}
                              {/* <a
                                href="tel: +91 2836 - 220431"
                                className="ph-no"
                              >
                                +91 2836 - 220431
                              </a>
                              <br /> */}
                              {/* <span>+91 9712980621</span> */}
                            </Col>
                          </Row>
                        </Col>
                        <Col md={3}>
                          <Row>
                            <Col md={12}>
                              <img
                                src={wa}
                                alt="whatsapp"
                                className="__MediumImg"
                              />
                            </Col>
                            <Col md={12} className="__CallText">
                              <a href="https://api.whatsapp.com/send?phone=919324147930" className="ph-no">
                                +91 93241 47930
                              </a>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Col>

                <Container className="__BrandContainer">
                  <Row>
                    <Col md={8} className="__ownerSite">
                      Copyright @ All Rights reserved - Designed & Maintained by
                      <a href="http://beakmedia.com/" className="__siteMaker">
                        {" "}
                        Beak Media
                      </a>
                    </Col>
                    <Col md={3} className="__pd20">
                      <Row>
                        <Col md={4}>
                          <a href="http://beakmedia.com/">
                            <img src={fb} alt="fg" className="__SocialImg" />
                          </a>
                        </Col>
                        <Col md={4}>
                          <a href="http://beakmedia.com/">
                            <img src={gp} alt="gp" className="__SocialImg" />
                          </a>
                        </Col>
                        <Col md={4}>
                          <a href="http://beakmedia.com/">
                            <img src={yt} alt="yt" className="__SocialImg" />
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Row>
          </Container>
        </section>

        <footer className="__footerformobile" style={{ marginTop: "50px" }}>
          <Container fluid>
            <Row>
              <Col md={12}>
                <p className="__packFooter" style={{ display: "none" }}></p>
              </Col>
              <Col md={12} className="__center">
                <Container className="__CusContainer">
                  <Row>
                    <Col>
                      <p className="__Connectus">CONNECT WITH US</p>
                    </Col>
                    <Col
                      md={12}
                      className="__mobileDiv"
                      style={{ marginTop: "20px" }}
                    >
                      <Row>
                        <Col md={12}>
                          <img
                            src={clock}
                            alt="clock"
                            className="__MediumImg"
                          />
                        </Col>
                        <Col md={12} className="__clockText">
                          <span>( Mon to Sat )</span>
                          <br />
                          <span> 9am to 6pm</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="__mobileDiv">
                      <Row>
                        <Col md={12}>
                          <img src={mail} alt="mail" className="__MediumImg" />
                        </Col>
                        <Col md={12} className="__MailText">
                          {/* <span>jaynatgim@gmail.com</span> */}
                          <a href="mailto:jaynatgim@gmail.com">
                            jayantgim@gmail.com
                          </a>
                          <br />
                          {/* <span>cargopackersgim@gmail.com</span> */}
                          <a href="mailto:cargopackersgim@gmail.com">
                            cargopackersgim@gmail.com
                          </a>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="__mobileDiv">
                      <Row>
                        <Col md={12}>
                          <img src={call} alt="call" className="__MediumImg" />
                        </Col>
                        <Col
                          md={12}
                          className="__CallText"
                          style={{ paddingBottom: "20px" }}
                        >
                          <a href="tel: +91 9712980621" className="ph-no">
                            +91 9712980621
                          </a>
                          <br />
                          {/* <span>+91 2836 - 221967</span> */}
                          <a href="tel: +91 2836-221967" className="ph-no">
                            +91 2836 - 221967
                          </a>
                          <br />
                          {/* <span>+91 2836 - 220431</span> */}
                          {/* <a href="tel: +91 2836 - 220431" className="ph-no">
                            +91 2836 - 220431
                          </a>
                          <br /> */}
                          {/* <span>+91 9712980621</span> */}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="__mobileDiv">
                      <Row>
                        <Col md={12}>
                          <img src={wa} alt="call" className="__MediumImg" />
                        </Col>
                        <Col
                          md={12}
                          className="__CallText"
                          style={{ paddingBottom: "20px" }}
                        >
                          <a href="https://api.whatsapp.com/send?phone=919324147930" className="ph-no">
                            +91 93241 47930
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
          <Container fluid className="__BrandContainer">
            <Col md={12} className="__ownerSite">
              Copyright @ All Rights reserved - Designed & Maintained by
              <a href="http://beakmedia.com/" className="__siteMaker">
                {" "}
                Beak Media
              </a>
            </Col>
            <Col
              md={12}
              style={{ textAlign: "center" }}
              className="__socioMobile"
            >
              <Row>
                {/* <Col md={4} className="cusCol4footer">
                  <a href="http://beakmedia.com/">
                    <img src={fb} alt="fg" className="__SocialImg" />
                  </a>
                </Col>
                <Col md={4} className="cusCol4footer">
                  <a href="http://beakmedia.com/">
                    <img src={gp} alt="gp" className="__SocialImg" />
                  </a>
                </Col>
                <Col md={4} className="cusCol4footer">
                  <a href="http://beakmedia.com/">
                    <img src={yt} alt="yt" className="__SocialImg" />
                  </a>
                </Col> */}
              </Row>
            </Col>
          </Container>
        </footer>
      </React.Fragment>
    );
  }
}

export default PackContactFooter;
