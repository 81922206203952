import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../Shared/Header";
import PackContact from "./PackContact";
import PackContactFooter from "./PackContactFooter";

export class MainContactPack extends Component {
  componentDidMount() {
    document.title = " Contact Us | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead">Contact Us</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <PackContact />
        <PackContactFooter />
      </React.Fragment>
    );
  }
}

export default MainContactPack;
