import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./MiddleSection.css";
import tigerLogo from "../../Components/assets/Images/tiger.png";
import Package from "../../Components/assets/Images/pack.png";
import Furniture from "../../Components/assets/Images/furniture.png";

function MiddleSection() {
  return (
    <Container className="cus-container">
      <Row className="pt-40">
        <Col md={4} className="disableinmobile">
          <div className="select-div">
            <p className="select-text">
              Please select your business of interest
            </p>
          </div>
          <div>
            <img src={tigerLogo} alt="MII" className="tiger-img" />
          </div>
        </Col>
        <Col md={12} className="displayinmobile">
          <div className="select-div">
            <p className="select-text">
              Please select your business of interest
            </p>
          </div>
        </Col>
        <Col md={4} className="cusCol12">
          <div className="card shadow">
            <Link to="/jcp/pack">
              <img className="card-img-top pt-10" src={Package} alt="Cardcap" />
            </Link>
            <div className="card-body cus-card-body">
              <p className="card-text">
                We are Providing World Class{" "}
                <span className="bold">Packaging Solutions </span>
                {""}
                for various kind of <span className="bold">
                  Export Cargo
                </span>{" "}
                and supplying man power and Packing Material for in-house
                Packing. Our trained & experienced team is known for fast &
                secure <span className="bold">Palletization</span> for{" "}
                <span className="bold">Export cargo</span>.
              </p>
              <Link
                to="/jcp/pack"
                exact
                className="btn btn-primary cus-btn cus-btn-primary"
              >
                Get Started
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="cusCol12">
          <div className="card shadow">
            <Link to="/jcp/furniture">
              <img
                className="card-img-top pt-10"
                src={Furniture}
                alt="Carcap"
              />
            </Link>
            <div className="card-body cus-card-body">
              <p className="card-text mrbottomextended">
                We specialize in manufacturing{" "}
                <span className="bold">Quality Doors </span>and{" "}
                <span className="bold">Furniture’s</span>. Our expert team has
                experience of handling large projects of{" "}
                <span className="bold">Hotels</span> and{" "}
                <span className="bold">Sanitoriums</span>.
              </p>
              <Link
                to="/jcp/furniture"
                exact
                className="btn btn-primary cus-btn cus-btn-primary"
              >
                Get Started
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default MiddleSection;
