import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import prev from "../assets/Images/prev.png";
import next from "../assets/Images/next.png";

import HM from "../assets/Images/hmfront.png";
import Tes from "../assets/Images/tensilefront.png";
import pp from "../assets/Images/pp.png";
import wb from "../assets/Images/wirebucklesfront.png";
import Frmb from "../assets/Images/bucklefront.png";
import sb from "../assets/Images/steelwirebuckle.png";
import sf from "../assets/Images/filmfront.png";
import ps from "../assets/Images/packingseal.png";
import pet from "../assets/Images/petfront.png";
import cord from "../assets/Images/cordstrapfront.png";
import rlb from "../assets/Images/rlb.png";
import ca from "../assets/Images/ca.png";
import da from "../assets/Images/dunnage2.png";
import lift from "../assets/Images/lifting_belt.png";
import sd from "../assets/Images/strapping_dispenser.png";
import sfd from "../assets/Images/stretch_film_dispenser.png";
import mc from "../assets/Images/metal_clip.png";
import bopp from "../assets/Images/bopp_tape_dispenser.png";
import hc from "../assets/Images/handstretch.png";
import pw from "../assets/Images/polyester_woven.png";
import ts from "../assets/Images/tape_sealing.png";

import dp from "../assets/Images/dp.png";

export class StrapTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hm: "active",
      pp: " ",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      ps: "",
      pet: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    };
  }
  componentDidMount() {
    document.title = "High Quality Strapping of cargo | JCP";
  }
  // ACTIVE DUMB FUNCTION
  hm = (e) => {
    this.setState({
      hm: "active",
      swb: "",
      sf: "",
      ps: "",
      pet: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  pp = (e) => {
    this.setState({
      hm: "",
      pp: "active",
      swb: "",
      sf: "",
      ps: "",
      pet: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  steel = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "active",
      swb: "",
      sf: "",
      ps: "",
      pet: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  wbuckle = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "active",
      swb: "",
      sf: "",
      ps: "",
      pet: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  fbuckle = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "active",
      swb: "",
      sf: "",
      ps: "",
      pet: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  swb = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "active",
      ps: "",
      pet: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  sf = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "active",
      ps: "",
      pet: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  pet = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      ps: "",
      pet: "active",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  ps = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      ps: "active",
      pet: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  cord = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "active",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  rlb = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "active",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  ca = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "",
      ca: "active",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  da = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "active",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  lift = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "active",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  pcs = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "active",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  sd = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "active",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  sfd = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "active",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  mc = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "active",
      bopp: "",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  bopp = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "active",
      hc: "",
      pw: "",
      ts: "",
    });
  };

  hc = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "active",
      pw: "",
      ts: "",
    });
  };

  pw = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "active",
      ts: "",
    });
  };

  ts = (e) => {
    this.setState({
      hm: "",
      pp: "",
      steel: "",
      wbuckle: "",
      fbuckle: "",
      swb: "",
      sf: "",
      pet: "",
      ps: "",
      cord: "",
      rlb: "",
      ca: "",
      da: "",
      lift: "",
      pcs: "",
      sd: "",
      sfd: "",
      mc: "",
      bopp: "",
      hc: "",
      pw: "",
      ts: "active",
    });
  };
  render() {
    return (
      <section className="__mtbot">
        <Container fluid className="__productPos">
          <Row>
            <Col md={12}>
              <div className="__productName"></div>
            </Col>
            <Col md={12} className=" __cros">
              {/* <div className="__productName"></div> */}
              <Container fluid className="__mTop">
                <Row>
                  <Col md={1} className="__endPrev">
                    <a href="#carouselExampleIndicators" data-slide="prev">
                      <img src={prev} alt="prev" className="__prevImg" />
                    </a>
                  </Col>
                  <Col md={10} className="padding0">
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                      data-interval="false"
                    >
                      <nav className="__navProduct __cusProduct">
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <div
                              id="nav-tab"
                              role="tablist"
                              className="nav nav-tabs __nav-tab navtab"
                            >
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.hm}`}
                                id="nav-pallets-tab"
                                data-toggle="tab"
                                href="#nav-pallets"
                                role="tab"
                                aria-controls="nav-pallets"
                                aria-selected="true"
                                onClick={this.hm.bind(this)}
                              >
                                HM COVER / HDPE COVER
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.sf}`}
                                id="nav-SF-tab"
                                data-toggle="tab"
                                href="#nav-SF"
                                role="tab"
                                aria-controls="nav-SF"
                                aria-selected="false"
                                onClick={this.sf.bind(this)}
                              >
                                STRETCH FILM
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.pp}`}
                                id="nav-PALLETIZATION-tab"
                                data-toggle="tab"
                                href="#nav-PALLETIZATION"
                                role="tab"
                                aria-controls="nav-PALLETIZATION"
                                aria-selected="false"
                                onClick={this.pp.bind(this)}
                              >
                                P. P. STRAPS
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.pet}`}
                                id="nav-pet-tab"
                                data-toggle="tab"
                                href="#nav-pet"
                                role="tab"
                                aria-controls="nav-pet"
                                aria-selected="false"
                                onClick={this.pet.bind(this)}
                              >
                                PET STRAPS
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.cord}`}
                                id="nav-cord-tab"
                                data-toggle="tab"
                                href="#nav-cord"
                                role="tab"
                                aria-controls="nav-cord"
                                aria-selected="false"
                                onClick={this.cord.bind(this)}
                              >
                                CORD STRAP
                              </a>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div
                              id="nav-tab"
                              role="tablist"
                              className="nav nav-tabs __nav-tab navtab"
                            >
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.steel}`}
                                id="nav-lc-tab"
                                data-toggle="tab"
                                href="#nav-lc"
                                role="tab"
                                aria-controls="nav-lc"
                                aria-selected="false"
                                onClick={this.steel.bind(this)}
                              >
                                STEEL STRAPS
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.ps}`}
                                id="nav-ps-tab"
                                data-toggle="tab"
                                href="#nav-ps"
                                role="tab"
                                aria-controls="nav-ps"
                                aria-selected="false"
                                onClick={this.ps.bind(this)}
                              >
                                PACKING SEAL
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.wbuckle}`}
                                id="nav-solution-tab"
                                data-toggle="tab"
                                href="#nav-solution"
                                role="tab"
                                aria-controls="nav-solution"
                                aria-selected="false"
                                onClick={this.wbuckle.bind(this)}
                              >
                                WIRE BUCKLE
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.fbuckle}`}
                                id="nav-Box-tab"
                                data-toggle="tab"
                                href="#nav-Box"
                                role="tab"
                                aria-controls="nav-Box"
                                aria-selected="false"
                                onClick={this.fbuckle.bind(this)}
                              >
                                FRAME BUCKLE
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.ca}`}
                                id="nav-ca-tab"
                                data-toggle="tab"
                                href="#nav-ca"
                                role="tab"
                                aria-controls="nav-ca"
                                aria-selected="false"
                                onClick={this.ca.bind(this)}
                              >
                                CORRUGATED ANGLES
                              </a>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div
                              id="nav-tab"
                              role="tablist"
                              className="nav nav-tabs __nav-tab navtab"
                            >
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.da}`}
                                id="nav-da-tab"
                                data-toggle="tab"
                                href="#nav-da"
                                role="tab"
                                aria-controls="nav-da"
                                aria-selected="false"
                                onClick={this.da.bind(this)}
                              >
                                DUNNAGE AIR BAG
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.rlb}`}
                                id="nav-rlb-tab"
                                data-toggle="tab"
                                href="#nav-rlb"
                                role="tab"
                                aria-controls="nav-rlb"
                                aria-selected="false"
                                onClick={this.rlb.bind(this)}
                              >
                                RATCHET LASHING BELT
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.lift}`}
                                id="nav-lift-tab"
                                data-toggle="tab"
                                href="#nav-lift"
                                role="tab"
                                aria-controls="nav-lift"
                                aria-selected="false"
                                onClick={this.lift.bind(this)}
                              >
                                LIFTING BELT
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.ts}`}
                                id="nav-ts-tab"
                                data-toggle="tab"
                                href="#nav-ts"
                                role="tab"
                                aria-controls="nav-ts"
                                aria-selected="false"
                                onClick={this.ts.bind(this)}
                              >
                                SEALER MACHINE
                              </a>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div
                              id="nav-tab"
                              role="tablist"
                              className="nav nav-tabs __nav-tab navtab"
                            >
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.hc}`}
                                id="nav-hs-tab"
                                data-toggle="tab"
                                href="#nav-hs"
                                role="tab"
                                aria-controls="nav-hs"
                                aria-selected="false"
                                onClick={this.hc.bind(this)}
                              >
                                HAND STRETCH FILM DISPENSER
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.bopp}`}
                                id="nav-bopp-tab"
                                data-toggle="tab"
                                href="#nav-bopp"
                                role="tab"
                                aria-controls="nav-bopp"
                                aria-selected="false"
                                onClick={this.bopp.bind(this)}
                              >
                                BOPP TAPE DISPENSER
                              </a>
                              <a
                                className={` nav-item nav-link __nav-link navlink ${this.state.sd}`}
                                id="nav-sd-tab"
                                data-toggle="tab"
                                href="#nav-sd"
                                role="tab"
                                aria-controls="nav-sd"
                                aria-selected="false"
                                onClick={this.sd.bind(this)}
                              >
                                STRAPPING DISPENSER
                              </a>
                            </div>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </Col>
                  <Col md={1} className="__endNext">
                    <a href="#carouselExampleIndicators" data-slide="next">
                      <img src={next} alt="next" className="__nextImg" />
                    </a>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={12} className="__pad">
              <div className="tab-content" id="nav-tabContent">
                {/* ONE */}
                <div
                  className="tab-pane fade active show  __hmBg __mtTop40"
                  id="nav-pallets"
                  role="tabpanel"
                  aria-labelledby="nav-pallets-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={HM} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">HM Cover/ HDPE Cover</h3>
                        </div>
                        <div>
                          <p className="__details">
                            We make HM/ HDPE Cover to suit the client’s Cargo
                            Needs. These covers are made from High Quality
                            Polythene. The manufacturing is done using hi- tech
                            machines and advanced technologies so as to ensure
                            the quality of the product.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* TWO */}
                <div
                  className="tab-pane fade __ppBg  __mtTop40"
                  id="nav-PALLETIZATION"
                  role="tabpanel"
                  aria-labelledby="nav-PALLETIZATION-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={pp} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">P.P. STRAPS</h3>
                        </div>
                        <div>
                          <p className="__details">
                            Strapping rolls are made from P.P. Materials. These
                            box strapping have high strength and high length to
                            weight ratio to give economical and efficient binder
                            cord.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* THREE */}
                <div
                  className="tab-pane fade __tesBg __mtTop40"
                  id="nav-lc"
                  role="tabpanel"
                  aria-labelledby="nav-lc-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={Tes} alt="s1" className="__st steel" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">
                            High Tensile Steel Straps
                          </h3>
                        </div>
                        <div>
                          <p className="__details">
                            High tensile Steel Strapping is manufactured from
                            cold rolled steel and heat treated to maximize break
                            strength and ductility. High tensile steel provides
                            additional break strength and shock resistance for
                            your toughest applications. Great option for
                            packaging that requires metal strapping.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* FOUR */}
                <div
                  className="tab-pane fade __wbBg __mtTop40"
                  id="nav-solution"
                  role="tabpanel"
                  aria-labelledby="nav-solution-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={wb} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">Wire Buckles</h3>
                        </div>
                        <div>
                          <p className="__details">
                            We are known as the distinguished trader, importer,
                            distributor, and suppliers of a wide range of Frame
                            Buckle.
                          </p>
                        </div>
                        {/* <div>
                          <h4 className="__wirebuckleproductdetail">
                            Product Details
                          </h4>
                        </div>
                        <div>
                          <table className="__wireBuckleTable">
                            <tbody>
                              <tr>
                                <td className="__serialNumberSP">1</td>
                                <td className="__productNameTableSP">Size</td>
                                <td>13 mm, 16 mm, 19 mm, 25 mm & 32 mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">2</td>
                                <td className="__productNameTableSP">
                                  Material
                                </td>
                                <td>Metal alloy / Steel body</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">3</td>
                                <td className="__productNameTableSP">Width</td>
                                <td>(Pcs/ unit) 40 mm 100/bag</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">4</td>
                                <td className="__productNameTableSP">Shape</td>
                                <td>Straped</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* FIVE */}
                <div
                  className="tab-pane fade __fbBg __mtTop40"
                  id="nav-Box"
                  role="tabpanel"
                  aria-labelledby="nav-Box-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={Frmb} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">Frame Buckle</h3>
                        </div>
                        <div>
                          <p className="__details">
                            Frame Buckles are made of high tenacity steel wire
                            and is used for the tightening of Cord Straps &
                            Plastic straps. These buckles are designed with
                            rigid process and controls to produce extremely
                            durable easy to thread and yield great holding
                            power. These buckles can be tensioned by tensioning
                            tools or hand.
                          </p>
                        </div>
                        {/* <div>
                          <h4 className="__wirebuckleproductdetail">
                            Product Details
                          </h4>
                        </div>
                        <div>
                          <table className="__wireBuckleTable">
                            <tbody>
                              <tr>
                                <td className="__serialNumberSP">1</td>
                                <td className="__productNameTableSP">Color</td>
                                <td>Brass</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">2</td>
                                <td className="__productNameTableSP">Width</td>
                                <td>35 mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">3</td>
                                <td className="__productNameTableSP">Type</td>
                                <td> Heavy duty one-way lashing system.</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">4</td>
                                <td className="__productNameTableSP">Shape</td>
                                <td>Frame</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* SIX */}
                <div
                  className="tab-pane fade __sbBg __mtTop40"
                  id="nav-SWB"
                  role="tabpanel"
                  aria-labelledby="nav-SWB-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={sb} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">Steel Wire Buckle</h3>
                        </div>
                        <div>
                          <p className="__details">
                            Steel Wire Buckles are made of high tenacity steel
                            wire and is used for the tightening of cord straps.
                            These buckles are designed with rigid process and
                            controls to produce extremely durable easy to thread
                            and yield great holding power. These are buckles can
                            be tensioned by tensioning tools or hand.
                          </p>
                        </div>
                        {/* <div>
                          <h4 className="__wirebuckleproductdetail">
                            Product Details
                          </h4>
                        </div>
                        <div>
                          <table className="__wireBuckleTable">
                            <tbody>
                              <tr>
                                <td className="__serialNumberSP">1</td>
                                <td className="__productNameTableSP">
                                  Material
                                </td>
                                <td>Steel</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">2</td>
                                <td className="__productNameTableSP">Size</td>
                                <td>13, 16, 19, 25, 32mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">3</td>
                                <td className="__productNameTableSP">
                                  PCS/ Box
                                </td>
                                <td> 250, 500, 1000</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">4</td>
                                <td className="__productNameTableSP">
                                  Surface
                                </td>
                                <td>Galvanize</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* SEVEN */}
                <div
                  className="tab-pane fade __sfBg __mtTop40"
                  id="nav-SF"
                  role="tabpanel"
                  aria-labelledby="nav-SF-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={sf} alt="s1" className="__st steel" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">Stretch Film</h3>
                        </div>
                        <div>
                          <p className="__stretchFilmPara">
                            Stretch wrap or Stretch film is a highly stretchable
                            plastic film that is wrapped around items. The
                            elastic recovery keeps the items tightly bound. In
                            contrast, shrink wrap is applied loosely around an
                            item and shrinks tightly with heat.
                          </p>
                          <p className="__stretchFilmPara">
                            It is frequently used to unitize pallet loads but
                            also may be used for bundling smaller items. Types
                            of stretch film include bundling stretch film, hand
                            stretch film, extended core stretch film, machine
                            stretch film and static dissipative film.
                          </p>
                        </div>
                        {/* <Row>
                          <Col md={6}>
                            <div>
                              <h4 className="__wirebuckleproductdetail">
                                MANUAL GRADE
                              </h4>
                            </div>
                            <div>
                              <table className="__wireBuckleTable ">
                                <tbody>
                                  <tr>
                                    <td className="__serialNumberSP">1</td>
                                    <td className="__productNameTableSP">
                                      Width
                                    </td>
                                    <td>450 mm</td>
                                  </tr>
                                  <tr>
                                    <td className="__serialNumberSP">2</td>
                                    <td className="__productNameTableSP">
                                      Thickness
                                    </td>
                                    <td>23 Micron</td>
                                  </tr>

                                  <tr>
                                    <td className="__serialNumberSP">3</td>
                                    <td className="__productNameTableSP">
                                      Weight
                                    </td>
                                    <td>3.5 Kg Net + Core</td>
                                  </tr>
                                  <tr>
                                    <td className="__serialNumberSP">4</td>
                                    <td className="__productNameTableSP">
                                      Packing
                                    </td>
                                    <td>6 Rolls Per Carton</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                          <Col md={6} className="__marginForSF">
                            <div>
                              <h4 className="__wirebuckleproductdetail">
                                MACHINE GRADE
                              </h4>
                            </div>
                            <div className="__sfTableAfter">
                              <table className="__wireBuckleTable">
                                <tbody>
                                  <tr>
                                    <td className="__serialNumberSP">1</td>
                                    <td className="__productNameTableSP">
                                      Width
                                    </td>
                                    <td>500 mm</td>
                                  </tr>
                                  <tr>
                                    <td className="__serialNumberSP">2</td>
                                    <td className="__productNameTableSP">
                                      Thickness
                                    </td>
                                    <td>29 Micron</td>
                                  </tr>
                                  <tr>
                                    <td className="__serialNumberSP">3</td>
                                    <td className="__productNameTableSP">
                                      Length
                                    </td>
                                    <td> 1130 Meters Per Roll</td>
                                  </tr>
                                  <tr>
                                    <td className="__serialNumberSP">4</td>
                                    <td className="__productNameTableSP">
                                      Weight
                                    </td>
                                    <td>15 Kg Net + Core</td>
                                  </tr>
                                  <tr>
                                    <td className="__serialNumberSP">5</td>
                                    <td className="__productNameTableSP">
                                      Packing
                                    </td>
                                    <td>1 Roll Per Carton</td>
                                  </tr>
                                  <tr>
                                    <td className="__serialNumberSP">6</td>
                                    <td className="__productNameTableSP">
                                      Stretchability
                                    </td>
                                    <td>250 %</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* EIGHT */}
                <div
                  className="tab-pane fade __psBg __mtTop40"
                  id="nav-ps"
                  role="tabpanel"
                  aria-labelledby="nav-ps-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={ps} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">PACKING SEAL</h3>
                        </div>
                        <div>
                          <p className="__details">
                            Packing seal is used to lock in the P.P Straps and
                            Pet Straps in place to secure and tighten the Cargo.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* NINE */}
                <div
                  className="tab-pane fade __petBg __mtTop40"
                  id="nav-pet"
                  role="tabpanel"
                  aria-labelledby="nav-pet-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={pet} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">PET STRAPS</h3>
                        </div>
                        <div>
                          <p className="__details">
                            PET Polyester Strap, which is produced from
                            environment protective “Polythene Terephthalate
                            “material or pet chips. Being a substitute of steel
                            strap it is used with manual strapping tool through
                            clip sealing or battery operated pet strap sealing
                            tool. Polyester strapping having its very high
                            elongation, is able to absorb shocks and impacts
                            during transportation and handling far better than
                            steel strapping is capable of.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* TEN */}
                <div
                  className="tab-pane fade __cordBg __mtTop40"
                  id="nav-cord"
                  role="tabpanel"
                  aria-labelledby="nav-cord-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={cord} alt="s1" className="__st steel" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">CORD STRAP</h3>
                        </div>
                        <div>
                          <p className="__details">
                            Cord strapping is made from polyester fibers,
                            creating one of the strongest non-metal strapping
                            products available with rugged durability. Cord
                            strapping is very easy to apply with minimum tooling
                            necessary for a secure fit. Cord Strap is available
                            in woven, stranded, and composite cords.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* ELEVEN */}
                <div
                  className="tab-pane fade __rlbBg __mtTop40"
                  id="nav-rlb"
                  role="tabpanel"
                  aria-labelledby="nav-rlb-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={rlb} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">Ratchet Lashing Belt</h3>
                        </div>
                        <div>
                          <p className="__details">
                            The Ratchet Lashing system is used for securing
                            cargo while transporting, shifting or storage. It is
                            a modern, light weight and thoroughly reliable
                            method of securing all types of load across an
                            entire spectrum of requirements. It offers
                            significant advantages over any other securing
                            system.
                          </p>
                        </div>
                        {/* <div>
                          <h4 className="__wirebuckleproductdetail">
                            Product Details
                          </h4>
                        </div>
                        <div>
                          <table className="__wireBuckleTable">
                            <tbody>
                              <tr>
                                <td className="__serialNumberSP">1</td>
                                <td className="__productNameTableSP">
                                  Size/Capacity
                                </td>
                                <td>
                                  25 mm 1 ton capacity/ 300 mm 24 ton capacity.
                                </td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">2</td>
                                <td className="__productNameTableSP">Width</td>
                                <td>25mm to 300 mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">3</td>
                                <td className="__productNameTableSP">Layers</td>
                                <td>Simplex, Duplex for different loads.</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">4</td>
                                <td className="__productNameTableSP">
                                  Material
                                </td>
                                <td>Polyester</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* TWELVE */}
                <div
                  className="tab-pane fade __caBg __mtTop40"
                  id="nav-ca"
                  role="tabpanel"
                  aria-labelledby="nav-ca-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={ca} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">CORRUGATED ANGLES</h3>
                        </div>
                        <div>
                          <p className="__details">
                            Corrugated Angles are used during transit to protect
                            corners of packed Cargo. This angle is available for
                            patrons in perfect sizes. Several layers of
                            corrugated paper in combination with paper or foam
                            on the inside give optimal cushioning and ideal
                            surface protection at the same time.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* THIRTEEN */}
                <div
                  className="tab-pane fade __daBg __mtTop40"
                  id="nav-da"
                  role="tabpanel"
                  aria-labelledby="nav-da-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={da} alt="s1" className="__st steel" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">DUNNAGE AIR BAG</h3>
                        </div>
                        <div>
                          <p className="__details">
                            Dunnage Air bags, also known as airbags, and
                            inflatable bags, are used to secure and stabilize
                            cargo. These dunnage bags provide convenient and
                            cost-effective cargo stabilization in ISO sea
                            containers, closed railcars, trucks, and oceangoing
                            vessels.
                          </p>
                        </div>

                        {/* <div>
                          <h3 className="__heading">Material</h3>
                        </div>
                        <div>
                          <p className="__details">
                            Material Medium consists of two layers of special
                            paper & PP woven laminated with paper, ensuring
                            maximum strength and low weight of airbag. On the
                            inside, medium consists of high strength PE quality
                            film, which is co-extruded, ensuring ultimate
                            airtight seal.
                          </p>
                        </div>

                        <div>
                          <h3 className="__heading">Valve</h3>
                        </div>
                        <div>
                          <p className="__details ">
                            Medium is equipped with a special valve, which
                            allows very quick inflation and deflation. The valve
                            can be opened and closed numerous times so the
                            airbag can be re used.
                          </p>
                        </div>

                        <div>
                          <h3 className="__heading">Product Details</h3>
                        </div>
                        <div>
                          <p className="__details __marginbottom">
                            Sizes in inches available :- 32 X 48, 32 X 72, 32 X
                            86, 36 X 48, 36 X 72, 36 X 84, 36 X 86, 36 X 96, 40
                            X 48, 40 X 72, 40 X 84, 40 X 96, 48 X 48, 48 X 72,
                            48 X 84, 48 X 86, 48 X 96
                          </p>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* FOURTEEN */}
                <div
                  className="tab-pane fade __liftBg __mtTop40"
                  id="nav-lift"
                  role="tabpanel"
                  aria-labelledby="nav-lift-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={lift} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">LIFTING BELT</h3>
                        </div>
                        <div>
                          <p className="__details">
                            Used for lifting various types of heavy material,
                            these products find application in many construction
                            sites and industries. These Lifting Belts are
                            available in many sizes in accordance to the needs.
                            These lifting belts are 100% high tenacity
                            lightweight polyester material is capable of
                            carrying heavy loads.
                          </p>
                        </div>
                        {/* <div>
                          <h4 className="__wirebuckleproductdetail">
                            Product Details
                          </h4>
                        </div>
                        <div>
                          <table className="__wireBuckleTable">
                            <tbody>
                              <tr>
                                <td className="__serialNumberSP">1</td>
                                <td className="__productNameTableSP">
                                  Size of Belts
                                </td>
                                <td>25, 32, 40, 50, 75mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">2</td>
                                <td className="__productNameTableSP">
                                  Capacity
                                </td>
                                <td>1, 1.5, 2, 5, 8ton</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">3</td>
                                <td className="__productNameTableSP">
                                  Application
                                </td>
                                <td>Lifting Tool</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* 15 */}
                <div
                  className="tab-pane fade __sdBg __mtTop40"
                  id="nav-sd"
                  role="tabpanel"
                  aria-labelledby="nav-sd-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={sd} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">STRAPPING DISPENSER</h3>
                        </div>
                        <div>
                          <p className="__details">
                            Handy & lightweight, Polypropylene Strap Dispensers
                            are designed for easy dispensing and use of strap.
                            Polypropylene (P. P.) strapping is the most commonly
                            used and least expensive type among all strapping
                            materials.
                          </p>
                        </div>
                        {/* <div>
                          <h4 className="__wirebuckleproductdetail">
                            Product Details
                          </h4>
                        </div>
                        <div>
                          <table className="__wireBuckleTable">
                            <tbody>
                              <tr>
                                <td className="__serialNumberSP">1</td>
                                <td className="__productNameTableSP">
                                  Wheel Type
                                </td>
                                <td>6inch steel</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">2</td>
                                <td className="__productNameTableSP">
                                  Core Size
                                </td>
                                <td>200 and 406 mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">3</td>
                                <td className="__productNameTableSP">
                                  Strap Width
                                </td>
                                <td>Up to 32 mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">4</td>
                                <td className="__productNameTableSP">
                                  Strap Type
                                </td>
                                <td>PET, PP, Steel</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">4</td>
                                <td className="__productNameTableSP">
                                  Item No
                                </td>
                                <td>CA 100 HS</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* 16 */}
                <div
                  className="tab-pane fade __sfdBg __mtTop40"
                  id="nav-sfd"
                  role="tabpanel"
                  aria-labelledby="nav-sfd-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={sfd} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">STRETCH FILM DISPENSER</h3>
                        </div>
                        <div>
                          <p className="__details">
                            With the help of our professionals, we are able to
                            trade, import, distribute and supply an extensive
                            collection of Stretch Film Dispenser.
                          </p>
                        </div>
                        {/* <div>
                          <h4 className="__wirebuckleproductdetail">
                            Product Details
                          </h4>
                        </div>
                        <div>
                          <table className="__wireBuckleTable">
                            <tbody>
                              <tr>
                                <td className="__serialNumberSP">1</td>
                                <td className="__productNameTableSP">Handle</td>
                                <td>Foam</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">2</td>
                                <td className="__productNameTableSP">
                                  For Core Size
                                </td>
                                <td>2" & 3"</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">3</td>
                                <td className="__productNameTableSP">
                                  Film Width
                                </td>
                                <td>12" to 20"</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* 17 */}
                <div
                  className="tab-pane fade __mcBg __mtTop40"
                  id="nav-mc"
                  role="tabpanel"
                  aria-labelledby="nav-mc-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={mc} alt="s1" className="__st mt12" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">METAL CLIP</h3>
                        </div>
                        <div>
                          <p className="__details">
                            By keeping track with the latest market
                            developments, we are engaged in offering our clients
                            an exclusive assortment of Metal Clip.
                          </p>
                        </div>
                        {/* <div>
                          <h4 className="__wirebuckleproductdetail">
                            Product Details
                          </h4>
                        </div>
                        <div>
                          <table className="__wireBuckleTable">
                            <tbody>
                              <tr>
                                <td className="__serialNumberSP">1</td>
                                <td className="__productNameTableSP">
                                  Band width
                                </td>
                                <td>9mm/12mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">2</td>
                                <td className="__productNameTableSP">
                                  Thickness
                                </td>
                                <td>0.7-0.8mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">3</td>
                                <td className="__productNameTableSP">Wrench</td>
                                <td>7mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">4</td>
                                <td className="__productNameTableSP">
                                  Material
                                </td>
                                <td>
                                  201 Stainless Steel, 301 Stainless Steel
                                  304,stainless steel 316
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* 18 */}
                <div
                  className="tab-pane fade __boppBg __mtTop40"
                  id="nav-bopp"
                  role="tabpanel"
                  aria-labelledby="nav-bopp-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={bopp} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">BOPP TAPE DISPENSER </h3>
                        </div>
                        <div>
                          <p className="__details">
                            The BOPP Tape Dispenser is widely used for fastening
                            packages and cartons in the e-commerce and logistics
                            industry. The tool is made from plastic and fibre
                            body and comes in 2 inch and 3 inch size.
                          </p>
                        </div>
                        {/* <div>
                          <h4 className="__wirebuckleproductdetail">
                            Product Details
                          </h4>
                        </div>
                        <div>
                          <table className="__wireBuckleTable">
                            <tbody>
                              <tr>
                                <td className="__serialNumberSP">1</td>
                                <td className="__productNameTableSP">Color</td>
                                <td>White</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">2</td>
                                <td className="__productNameTableSP">Width</td>
                                <td>2 and 3 inch</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">3</td>
                                <td className="__productNameTableSP">
                                  Material
                                </td>
                                <td>Plastic body</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">4</td>
                                <td className="__productNameTableSP">Type</td>
                                <td>BOPP Tape</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* 19 */}
                <div
                  className="tab-pane fade __hcBg __mtTop40"
                  id="nav-hs"
                  role="tabpanel"
                  aria-labelledby="nav-hs-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={hc} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">
                            HAND STRETCH FILM DISPENSER
                          </h3>
                        </div>
                        <div>
                          <p className="__details">
                            This hand stretch film dispenser is used to Wrap
                            Stretch Film to pallet loads to ensure safe and
                            secure delivery. Self-adhering-each layer of film
                            bonds to the next for added strength and Protects
                            shipments from water, dirt, and damage caused by
                            excess movement.
                          </p>
                        </div>
                        {/* <div>
                          <h4 className="__wirebuckleproductdetail">
                            Product Details
                          </h4>
                        </div>
                        <div>
                          <table className="__wireBuckleTable">
                            <tbody>
                              <tr>
                                <td className="__serialNumberSP">1</td>
                                <td className="__productNameTableSP">Type</td>
                                <td>Stretch Film</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">2</td>
                                <td className="__productNameTableSP">
                                  Film Width
                                </td>
                                <td>300 mm to 500 mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">3</td>
                                <td className="__productNameTableSP">
                                  For Core Size
                                </td>
                                <td>48 mm & 72 mmy</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">4</td>
                                <td className="__productNameTableSP">Handle</td>
                                <td>Foam</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* 20 */}
                <div
                  className="tab-pane fade __pwBg __mtTop40"
                  id="nav-pw"
                  role="tabpanel"
                  aria-labelledby="nav-pw-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={pw} alt="s1" className="__st mt12" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">
                            POLYESTER WOVEN STRAPPING
                          </h3>
                        </div>
                        <div>
                          <p className="__details">
                            We have carved a niche as a leading trader,
                            importer, distributor, and suppliers of an
                            intricately designed array of Polyester Woven
                            Strapping.
                          </p>
                        </div>
                        {/* <div>
                          <h4 className="__wirebuckleproductdetail">
                            Product Details
                          </h4>
                        </div>
                        <div>
                          <table className="__wireBuckleTable">
                            <tbody>
                              <tr>
                                <td className="__serialNumberSP">1</td>
                                <td className="__productNameTableSP">
                                  Material
                                </td>
                                <td>Polyester</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">2</td>
                                <td className="__productNameTableSP">Size</td>
                                <td>12mm, 16mm, 19mm, 25mm & 32mm</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">3</td>
                                <td className="__productNameTableSP">
                                  System Breaking Strength
                                </td>
                                <td>3 - 7.5 to</td>
                              </tr>
                              <tr>
                                <td className="__serialNumberSP">4</td>
                                <td className="__productNameTableSP">
                                  Length(Meters)
                                </td>
                                <td>100 - 250 m</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* 21 */}
                <div
                  className="tab-pane fade __tsBg __mtTop40"
                  id="nav-ts"
                  role="tabpanel"
                  aria-labelledby="nav-ts-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          <img src={ts} alt="s1" className="__st" />
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">
                            TENSIONER AND SEALER MACHINE
                          </h3>
                        </div>
                        <div>
                          <p className="__details">
                            Tensioner and Sealer Machine and Tension Punch
                            machine are used to secure Box Strapping.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* ACCORDION STARTS */}
        <section className="__accordionForMobile disableInDesktop">
          <div className="__productName"></div>
          <Container fluid className="cusFluidaccordian">
            <div className="accordion cusAccor" id="accordionExample">
              {/* FIRST */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingOne"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style={{ zIndex: "99" }}
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion"
                      type="button"
                    >
                      HM COVER/ HDPE COVER
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={HM} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        We make HM/ HDPE Cover to suit the client’s Cargo Needs.
                        These covers are made from High Quality Polythene. The
                        manufacturing is done using hi- tech machines and
                        advanced technologies so as to ensure the quality of the
                        product.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 2 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="heading7"
                  data-toggle="collapse"
                  data-target="#collapse7"
                  aria-expanded="false"
                  aria-controls="collapse7"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      STRETCH FILM
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapse7"
                  className="collapse"
                  aria-labelledby="heading7"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={sf} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__stretchFilmPara">
                        Stretch wrap or Stretch film is a highly stretchable
                        plastic film that is wrapped around items. The elastic
                        recovery keeps the items tightly bound. In contrast,
                        shrink wrap is applied loosely around an item and
                        shrinks tightly with heat.
                      </p>
                      <p className="__stretchFilmPara">
                        It is frequently used to unitize pallet loads but also
                        may be used for bundling smaller items. Types of stretch
                        film include bundling stretch film, hand stretch film,
                        extended core stretch film, machine stretch film and
                        static dissipative film.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 3 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingTwo"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      P.P. STRAPS
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={pp} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        Strapping rolls are made from P.P. Materials. These box
                        strapping have high strength and high length to weight
                        ratio to give economical and efficient binder cord.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 4 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="heading9"
                  data-toggle="collapse"
                  data-target="#collapse9"
                  aria-expanded="false"
                  aria-controls="collapse9"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      PET STRAPS
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapse9"
                  className="collapse"
                  aria-labelledby="heading9"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={pet} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        PET Polyester Strap, which is produced from environment
                        protective “Polythene Terephthalate “material or pet
                        chips. Being a substitute of steel strap it is used with
                        manual strapping tool through clip sealing or battery
                        operated pet strap sealing tool. Polyester strapping
                        having its very high elongation, is able to absorb
                        shocks and impacts during transportation and handling
                        far better than steel strapping is capable of.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 5 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="heading10"
                  data-toggle="collapse"
                  data-target="#collapse10"
                  aria-expanded="false"
                  aria-controls="collapse10"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      CORD STRAP
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapse10"
                  className="collapse"
                  aria-labelledby="heading10"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={cord} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        Cord strapping is made from polyester fibers, creating
                        one of the strongest non-metal strapping products
                        available with rugged durability. Cord strapping is very
                        easy to apply with minimum tooling necessary for a
                        secure fit. Cord Strap is available in woven, stranded,
                        and composite cords.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 6 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingThree"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      HIGH TENSILE STEEL STRAPS
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={Tes} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        High tensile Steel Strapping is manufactured from cold
                        rolled steel and heat treated to maximize break strength
                        and ductility. High tensile steel provides additional
                        break strength and shock resistance for your toughest
                        applications. Great option for packaging that requires
                        metal strapping.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 7 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="heading8"
                  data-toggle="collapse"
                  data-target="#collapse8"
                  aria-expanded="false"
                  aria-controls="collapse8"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      PACKING SEAL
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapse8"
                  className="collapse"
                  aria-labelledby="heading8"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={ps} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        Packing seal is used to lock in the P.P Straps and Pet
                        Straps in place to secure and tighten the Cargo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 8 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingFour"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      WIRE BUCKLES
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={wb} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        We are known as the distinguished trader, importer,
                        distributor, and suppliers of a wide range of Frame
                        Buckle.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 9 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingFive"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      FRAME BUCKLE
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseFive"
                  className="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={Frmb} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        Frame Buckles are made of high tenacity steel wire and
                        is used for the tightening of Cord Straps & Plastic
                        straps. These buckles are designed with rigid process
                        and controls to produce extremely durable easy to thread
                        and yield great holding power. These buckles can be
                        tensioned by tensioning tools or hand.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 10 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="heading12"
                  data-toggle="collapse"
                  data-target="#collapse12"
                  aria-expanded="false"
                  aria-controls="collapse12"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      CORRUGATED ANGLES
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapse12"
                  className="collapse"
                  aria-labelledby="heading12"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={ca} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        Corrugated Angles are used during transit to protect
                        corners of packed Cargo. This angle is available for
                        patrons in perfect sizes. Several layers of corrugated
                        paper in combination with paper or foam on the inside
                        give optimal cushioning and ideal surface protection at
                        the same time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 11 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="heading13"
                  data-toggle="collapse"
                  data-target="#collapse13"
                  aria-expanded="false"
                  aria-controls="collapse13"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      DUNNAGE AIR BAG
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapse13"
                  className="collapse"
                  aria-labelledby="heading13"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={da} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        Dunnage Air bags, also known as airbags, and inflatable
                        bags, are used to secure and stabilize cargo. These
                        dunnage bags provide convenient and cost-effective cargo
                        stabilization in ISO sea containers, closed railcars,
                        trucks, and oceangoing vessels.
                      </p>
                      <p className="__details">
                        In Many cases only a few Air Bags are needed to secure
                        even the largest cargo. Using Dunnage Air bag you are
                        sure that your transport arrives undamaged at their
                        destination & You do not pay more than necessary.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 12 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="heading11"
                  data-toggle="collapse"
                  data-target="#collapse11"
                  aria-expanded="false"
                  aria-controls="collapse11"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      RATCHET LASHING BELT
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapse11"
                  className="collapse"
                  aria-labelledby="heading11"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={rlb} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        The Ratchet Lashing system is used for securing cargo
                        while transporting, shifting or storage. It is a modern,
                        light weight and thoroughly reliable method of securing
                        all types of load across an entire spectrum of
                        requirements. It offers significant advantages over any
                        other securing system.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 13 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="heading14"
                  data-toggle="collapse"
                  data-target="#collapse14"
                  aria-expanded="false"
                  aria-controls="collapse14"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      LIFTING BELT
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapse14"
                  className="collapse"
                  aria-labelledby="heading14"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={lift} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        Used for lifting various types of heavy material, these
                        products find application in many construction sites and
                        industries. These Lifting Belts are available in many
                        sizes in accordance to the needs. These lifting belts
                        are 100% high tenacity lightweight polyester material is
                        capable of carrying heavy loads.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 14 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="heading22"
                  data-toggle="collapse"
                  data-target="#collapse22"
                  aria-expanded="false"
                  aria-controls="collapse22"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      SEALER MACHINE
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapse22"
                  className="collapse"
                  aria-labelledby="heading22"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={ts} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        Tensioner and Sealer Machine and Tension Punch machine
                        are used to secure Box Strapping.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 15 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="heading20"
                  data-toggle="collapse"
                  data-target="#collapse20"
                  aria-expanded="false"
                  aria-controls="collapse20"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      HAND STRETCH FILM DISPENSER
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapse20"
                  className="collapse"
                  aria-labelledby="heading20"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={hc} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        This hand stretch film dispenser is used to Wrap Stretch
                        Film to pallet loads to ensure safe and secure delivery.
                        Self-adhering-each layer of film bonds to the next for
                        added strength and Protects shipments from water, dirt,
                        and damage caused by excess movement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 16 */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="heading19"
                  data-toggle="collapse"
                  data-target="#collapse19"
                  aria-expanded="false"
                  aria-controls="collapse19"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      BOPP TAPE DISPENSER
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapse19"
                  className="collapse"
                  aria-labelledby="heading19"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      <img src={bopp} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        The BOPP Tape Dispenser is widely used for fastening
                        packages and cartons in the e-commerce and logistics
                        industry. The tool is made from plastic and fibre body
                        and comes in 2 inch and 3 inch size.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </section>
    );
  }
}

export default StrapTools;
