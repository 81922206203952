import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

// Images
import s1 from "./assets/Images/gma1.png";
import bx1 from "./assets/Images/bx1.png";
import bx2 from "./assets/Images/bx2.png";
import bx3 from "./assets/Images/bx3.png";
import s2 from "./assets/Images/palletization/palletization.png";
// import s3 from "./assets/Images/lashfront.png";
import l1 from "./assets/Images/lash/lfront.png";
import s4 from "./assets/Images/strapColl.png";
import crt from "./assets/Images/caretRT.png";
import Button2 from "../Shared/Button2";
import dp from "./assets/Images/dp.png";
import eye from "./assets/Images/eye.png";
export class ProductsPack extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    return (
      <React.Fragment>
        <section className="__pdbt40 disableInMobile">
          <Container fluid className="__pdTop">
            <Row>
              <Col md={12}>
                <div className="__ProductsHead"></div>
              </Col>
              <Col md={12}>
                <nav className="__navProduct">
                  <div
                    className="nav nav-tabs __nav-tab"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className="nav-item nav-link __nav-link active"
                      id="nav-pallets-tab"
                      data-toggle="tab"
                      href="#nav-pallets"
                      role="tab"
                      aria-controls="nav-pallets"
                      aria-selected="true"
                    >
                      PALLETS
                    </a>
                    <a
                      className="nav-item nav-link __nav-link"
                      id="nav-PALLETIZATION-tab"
                      data-toggle="tab"
                      href="#nav-PALLETIZATION"
                      role="tab"
                      aria-controls="nav-PALLETIZATION"
                      aria-selected="false"
                    >
                      PALLETIZATION
                    </a>
                    <a
                      className="nav-item nav-link __nav-link"
                      id="nav-lc-tab"
                      data-toggle="tab"
                      href="#nav-lc"
                      role="tab"
                      aria-controls="nav-lc"
                      aria-selected="false"
                    >
                      LASHING / CHOCKING
                    </a>
                    <a
                      className="nav-item nav-link __nav-link"
                      id="nav-solution-tab"
                      data-toggle="tab"
                      href="#nav-solution"
                      role="tab"
                      aria-controls="nav-solution"
                      aria-selected="false"
                    >
                      STRAPPING SOLUTIONS
                    </a>
                    <a
                      className="nav-item nav-link __nav-link"
                      id="nav-Box-tab"
                      data-toggle="tab"
                      href="#nav-Box"
                      role="tab"
                      aria-controls="nav-Box"
                      aria-selected="false"
                    >
                      BOX & CRATES
                    </a>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active __tabImg1 __mtTop40"
                    id="nav-pallets"
                    role="tabpanel"
                    aria-labelledby="nav-pallets-tab"
                  >
                    <Container>
                      <Row>
                        <Col md={12}>
                          <section>
                            <Container>
                              <Row>
                                <Col md={5} className="__s1Pos">
                                  <img src={s1} alt="s1" className="__s1Img" />
                                </Col>
                                <Col md={7}>
                                  <div className="__PalletsPara __pdtop40">
                                    <h3>PALLETS</h3>
                                    {/* <p>
                                      Jayant Packing Manufactures Industry Standard
                                      Wooden Pallets For A Variety Of Needs And
                                      Industries: Euro-Type Pallets, CP-Type
                                      Pallets, Boxes, Crates, And More.{" "}
                                    </p>
                                    <p>
                                      We Also Customize Wooden Pallets For Our
                                      Customers, Depending On Their Needs
                                    </p> */}
                                    <p>
                                      Jayant Packing Manufactures Industry
                                      Standard As Well As Custom Designed
                                      Pallets To Suit The Need Of Customer's
                                      Requirement.
                                    </p>
                                    <Button2
                                      title="View all"
                                      btnCSS="buttonProduct"
                                      alt="icon"
                                      img={crt}
                                      iconDes="__crtIconProduct"
                                      whereto="/jcp/pallet"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </section>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div
                    className="tab-pane fade __tabImg2 __mtTop40"
                    id="nav-PALLETIZATION"
                    role="tabpanel"
                    aria-labelledby="nav-PALLETIZATION-tab"
                  >
                    <Container>
                      <Row>
                        <Col md={12}>
                          <section>
                            <Container>
                              <Row>
                                <Col md={5} className="__s1Pos top25">
                                  <img src={s2} alt="s1" className="__s1Img2" />
                                </Col>
                                <Col md={7}>
                                  <div className="__PalletsPara __pdtop40">
                                    <h3>PALLETIZATION</h3>
                                    <p>
                                      We Are Providing World Class Palletization
                                      Service To The Process Of Export Cargo Or
                                      Materials, Either Packaged Or Bulk, Onto
                                      Pallets. Palletization Provides A Base For
                                      The Goods And Materials, Thereby Promoting
                                      The Efficient Storage, Our Experienced And
                                      Skilled Staff Is Always Ready To Serve Our
                                      Customer Best, Handling And Transport For
                                      The Combination Of Goods And The Pallet
                                      Base, With Proper Stacking &amp; Wrapping
                                      To Make Export Cargo Which Makes Efficient
                                      &amp; Safe Material Handling. It Also
                                      Reduces Chances Of Worker Injury.
                                    </p>
                                    <Button2
                                      title="View all"
                                      btnCSS="buttonProduct"
                                      alt="icon"
                                      img={crt}
                                      iconDes="__crtIconProduct"
                                      whereto="/jcp/palletization"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </section>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div
                    className="tab-pane fade __tabImg3 __mtTop40"
                    id="nav-lc"
                    role="tabpanel"
                    aria-labelledby="nav-lc-tab"
                  >
                    <Container>
                      <Row>
                        <Col md={12}>
                          <section>
                            <Container>
                              <Row>
                                <Col md={5} className="__s1Pos top25">
                                  <img src={l1} alt="s1" className="__s1Img3" />
                                </Col>
                                <Col md={7}>
                                  <div className="__PalletsPara __pdtop40">
                                    <h3>LASHING & CHOCKING</h3>
                                    <p>
                                      We Have Years Of Experience In Stuffing,
                                      Lashing and Choking Of Various Types Of
                                      Cargo In Containers As Well As Break Bulk
                                      Cargo. Lashing and Choking Techniques Are
                                      Customized To Suit The Cargo.{" "}
                                    </p>
                                    <p>
                                      Commonly Used Types Of Lashing Include
                                      Loop Lashing, Direct Lashing, Tie Down
                                      Lashing, Cross Lashing And Harness
                                      Lashing. We Have Skilled Staff And Workmen
                                      Who Have Experience In Lashing And Choking
                                      Of Various Types Of Cargo Including Steel
                                      Coils And Sheets, Automobiles,
                                      Palletized/Unitized Cargo, Machinery And
                                      Equipment, ODC Cargo, Container Cargo,
                                      Etc.
                                    </p>
                                    <Button2
                                      title="View all"
                                      btnCSS="buttonProduct"
                                      alt="icon"
                                      img={crt}
                                      iconDes="__crtIconProduct"
                                      whereto="/jcp/lashing"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </section>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div
                    className="tab-pane fade __tabImg4 __mtTop40"
                    id="nav-solution"
                    role="tabpanel"
                    aria-labelledby="nav-solution-tab"
                  >
                    <Container>
                      <Row>
                        <Col md={12}>
                          <section>
                            <Container>
                              <Row>
                                <Col md={5} className="__s1Pos top25">
                                  <img src={s4} alt="s1" className="__s1Img4" />
                                </Col>
                                <Col md={7}>
                                  <div className="__PalletsPara __pdtop40">
                                    <h3>STRAPPING SOLUTIONS</h3>
                                    <p>
                                      Strapping Solutions Is A Wide Variety Of
                                      Products &amp; Tools Of Plastic Strap, Pet
                                      Strap, Cord Strap, Steel Strap, HM Covers,
                                      HDPE Covers, Tensioner Machines, Punching
                                      Machine, Packing Seals, Buckles &amp;
                                      Strapping Tools, And Accessories. Our
                                      Strapping Equipment And Systems Are
                                      Designed For A Multitude Of Applications
                                      And Industries
                                    </p>
                                    <p>
                                      Our Trusted Vendors Supplies Us Advanced
                                      &amp; Best Quality Tools Which Makes
                                      Strapping &amp; Wrapping Effortless &amp;
                                      Eore Efficiency.
                                    </p>
                                    <Button2
                                      title="View all"
                                      btnCSS="buttonProduct"
                                      alt="icon"
                                      img={crt}
                                      iconDes="__crtIconProduct"
                                      whereto="/jcp/strap"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </section>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div
                    className="tab-pane fade __mtTop40"
                    id="nav-Box"
                    role="tabpanel"
                    aria-labelledby="nav-Box-tab"
                  >
                    <Container>
                      <Row>
                        <Col md={12}>
                          <section>
                            <Container>
                              <Row>
                                <Col md={4} className="__s1Pos">
                                  <a href="/jcp/box">
                                    {/* <img src={g2} className="__sliderImgs" alt="" /> */}
                                    <img src={bx1} alt="" className="__s1Img" />
                                    <img src={eye} className="__eye" alt="" />
                                    <span className="__vieweye">view all</span>
                                  </a>
                                </Col>
                                <Col md={4} className="__s1Pos">
                                  {/* <img src={bx2} alt="s1" className="__s1Img" /> */}
                                  <a href="/jcp/box">
                                    {/* <img src={g2} className="__sliderImgs" alt="" /> */}
                                    <img src={bx2} alt="" className="__s1Img" />
                                    <img src={eye} className="__eye" alt="" />
                                    <span className="__vieweye">view all</span>
                                  </a>
                                </Col>
                                <Col md={4} className="__s1Pos">
                                  {/* <img src={bx3} alt="s1" className="__s1Img" /> */}
                                  <a href="/jcp/box">
                                    {/* <img src={g2} className="__sliderImgs" alt="" /> */}
                                    <img src={bx3} alt="" className="__s1Img" />
                                    <img src={eye} className="__eye" alt="" />
                                    <span className="__vieweye">view all</span>
                                  </a>
                                </Col>
                              </Row>
                            </Container>
                          </section>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="__accordionForMobile disableInDesktop">
          <div className="__ProductsHead"></div>
          <Container fluid className="cusFluidaccordian">
            <div className="accordion cusAccor" id="accordionExample">
              {/* FIRST */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingOne"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion"
                      type="button"
                    >
                      PALLETS
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body cuscardbody">
                    <div className="_accordionImg">
                      <img src={s1} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p>
                        Jayant Packing Manufactures Industry Standard As Well As
                        Custom Designed Pallets To Suit The Need Of Customer's
                        Requirement.
                      </p>
                    </div>
                    <Button2
                      title="View all"
                      btnCSS="accorbuttonProduct"
                      alt="icon"
                      img={crt}
                      iconDes="__crtIconProduct"
                      whereto="/jcp/pallet"
                    />
                  </div>
                </div>
              </div>
              {/* SECOND */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingTwo"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      PALLETIZATION
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body cuscardbody">
                    <div className="_accordionImg">
                      <img src={s2} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p>
                        We Are Providing World Class Palletization Service To
                        The Process Of Export Cargo Or Materials, Either
                        Packaged Or Bulk, Onto Pallets. Palletization Provides A
                        Base For The Goods And Materials, Thereby Promoting The
                        Efficient Storage, Our Experienced And Skilled Staff Is
                        Always Ready To Serve Our Customer Best, Handling And
                        Transport For The Combination Of Goods And The Pallet
                        Base, With Proper Stacking &amp; Wrapping To Make Export
                        Cargo Which Makes Efficient &amp; Safe Material
                        Handling. It Also Reduces Chances Of Worker Injury.
                      </p>
                    </div>
                    <Button2
                      title="View all"
                      btnCSS="accorbuttonProduct"
                      alt="icon"
                      img={crt}
                      iconDes="__crtIconProduct"
                      whereto="/jcp/palletization"
                    />
                  </div>
                </div>
              </div>
              {/* THIRD*/}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingThree"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      LASHING&CHOCKING
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body cuscardbody">
                    <div className="_accordionImg">
                      <img src={l1} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p>
                        We Have Years Of Experience In Stuffing, Lashing and
                        Choking Of Various Types Of Cargo In Containers As Well
                        As Break Bulk Cargo. Lashing and Choking Techniques Are
                        Customized To Suit The Cargo.
                      </p>
                      <p>
                        Commonly Used Types Of Lashing Include Loop Lashing,
                        Direct Lashing, Tie Down Lashing, Cross Lashing And
                        Harness Lashing. We Have Skilled Staff And Workmen Who
                        Have Experience In Lashing And Choking Of Various Types
                        Of Cargo Including Steel Coils And Sheets, Automobiles,
                        Palletized/Unitized Cargo, Machinery And Equipment, ODC
                        Cargo, Container Cargo, Etc.
                      </p>
                    </div>
                    <Button2
                      title="View all"
                      btnCSS="accorbuttonProduct"
                      alt="icon"
                      img={crt}
                      iconDes="__crtIconProduct"
                      whereto="/jcp/lashing"
                    />
                  </div>
                </div>
              </div>
              {/* FOURTH */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingFour"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      STRAPPING SOLUTIONS
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div className="card-body cuscardbody">
                    <div className="_accordionImg">
                      <img src={s4} alt="" />
                    </div>
                    <div className="accordionPara">
                      <p>
                        Strapping Solutions Is A Wide Variety Of Products &amp;
                        Tools Of Plastic Strap, Pet Strap, Cord Strap, Steel
                        Strap, HM Covers, HDPE Covers, Tensioner Machines,
                        Punching Machine, Packing Seals, Buckles &amp; Strapping
                        Tools, And Accessories. Our Strapping Equipment And
                        Systems Are Designed For A Multitude Of Applications And
                        Industries
                      </p>
                      <p>
                        Our Trusted Vendors Supplies Us Advanced &amp; Best
                        Quality Tools Which Makes Strapping &amp; Wrapping
                        Effortless &amp; Eore Efficiency.
                      </p>
                    </div>
                    <Button2
                      title="View all"
                      btnCSS="accorbuttonProduct"
                      alt="icon"
                      img={crt}
                      iconDes="__crtIconProduct"
                      whereto="/jcp/strap"
                    />
                  </div>
                </div>
              </div>
              {/* FIFTH */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingFive"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion collapsed"
                      type="button"
                    >
                      BOX & CRATES
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <div
                  id="collapseFive"
                  className="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordionExample"
                >
                  <div className="card-body cuscardbody">
                    <div className="_accordionImg">
                      <img src={bx1} alt="" />
                    </div>
                    {/* <div className="accordionPara">
                      <p>
                        Jayant Packing manufactures standard wooden pallets for
                        every purpose and Serving all Field of Industries Euro
                        Type Pallets, CP Type Pallets as well as Customized
                        Wooden Pallets, Boxes, Crates & other Wooden Packaging
                        Solutions.
                      </p>
                    </div> */}
                    <Button2
                      title="View all"
                      btnCSS="accorbuttonProduct"
                      alt="icon"
                      img={crt}
                      iconDes="__crtIconProduct"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ProductsPack;
