import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

// import prev from "../assets/Images/prev.png";
// import next from "../assets/Images/next.png";
// import s1 from "../assets/Images/lashfront.png";

import l1 from "../assets/Images/lash/l1.png";
import l2 from "../assets/Images/lash/l2.png";
import l3 from "../assets/Images/lash/l3.png";
import l4 from "../assets/Images/lash/l4.png";
import l5 from "../assets/Images/lash/l5.png";
import l6 from "../assets/Images/lash/l6.png";
import dp from "../assets/Images/dp.png";
import lt from "../assets/Images/arrowlt.png";
import rt from "../assets/Images/arrowrt.png";

export class Lashing extends Component {
  componentDidMount() {
    document.title = "Lashing and Choking services for Various types of Cargo | JCP";
  }
  render() {
    return (
      <section className="__mtbot">
        <Container fluid className="__productPos">
          <Row>
            <Col md={12}>
              <div className="__productNameLashing"></div>
            </Col>
            <Col md={12} className=" __cros">
              <Container fluid className="__mTop">
                <Row>
                  <Col md={1} className="__endPrev"></Col>
                  <Col md={10}>
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <nav className="__navProduct __cusProduct">
                            <div
                              className="nav nav-tabs __nav-tab navtab"
                              id="nav-tab"
                              role="tablist"
                            >
                              <a
                                className="nav-item nav-link __nav-link navlink active"
                                id="nav-pallets-tab"
                                data-toggle="tab"
                                href="#nav-pallets"
                                role="tab"
                                aria-controls="nav-pallets"
                                aria-selected="true"
                              >
                                LASHING & CHOCKING
                              </a>
                            </div>
                          </nav>
                        </div>
                        {/* Paste ur code here */}
                      </div>
                    </div>
                  </Col>
                  <Col md={1} className="__endNext"></Col>
                </Row>
              </Container>
            </Col>
            <Col md={12} className="__pad">
              <div className="tab-content" id="nav-tabContent">
                {/* ONE */}
                <div
                  className="tab-pane fade show active __lashBg __mtTop40"
                  id="nav-pallets"
                  role="tabpanel"
                  aria-labelledby="nav-pallets-tab"
                >
                  <Container fluid className="__padBoth">
                    <Row>
                      <Col md={5} className="__pad0">
                        <div>
                          {/* <img src={s1} alt="s1" className="__st __stlash" /> */}
                          <div
                            id="carouselExampleControlspal1"
                            className="carousel slide"
                            data-ride="carousel"
                          >
                            <div className="carousel-inner">
                              <div className="carousel-item centerAlign active">
                                {" "}
                                <img src={l1} alt="s1" className="__st " />{" "}
                              </div>
                              <div className="carousel-item centerAlign">
                                {" "}
                                <img src={l2} alt="s1" className="__st " />{" "}
                              </div>
                              <div className="carousel-item centerAlign">
                                {" "}
                                <img src={l3} alt="s1" className="__st " />{" "}
                              </div>
                              <div className="carousel-item centerAlign">
                                {" "}
                                <img src={l4} alt="s1" className="__st " />{" "}
                              </div>
                              <div className="carousel-item centerAlign">
                                {" "}
                                <img src={l5} alt="s1" className="__st " />{" "}
                              </div>
                              <div className="carousel-item centerAlign">
                                {" "}
                                <img src={l6} alt="s1" className="__st " />{" "}
                              </div>
                            </div>
                            <div className="navigateBtn2">
                              <button
                                className="buttonGallery"
                                href="#carouselExampleControlspal1"
                                data-slide="prev"
                              >
                                <img src={lt} className="__arrowimg" alt="" />
                              </button>
                              <button
                                className="buttonGallery"
                                href="#carouselExampleControlspal1"
                                data-slide="next"
                              >
                                <img src={rt} className="__arrowimg" alt="" />
                              </button>
                            </div>
                            {/* <a
                              className="carousel-control-prev"
                              href="#carouselExampleControlspal1"
                              role="button"
                              data-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Previous</span>
                            </a>
                            <a
                              className="carousel-control-next"
                              href="#carouselExampleControlspal1"
                              role="button"
                              data-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Next</span>
                            </a> */}
                          </div>
                        </div>
                      </Col>
                      <Col md={7} className="__paraArea">
                        <div>
                          <h3 className="__heading">LASHING & CHOCKING</h3>
                        </div>
                        <div>
                          <p className="__details">
                            We have years of experience in Stuffing, Lashing and
                            Choking of Various types of Cargo in Containers as
                            well as Break Bulk Cargo. Since each Cargo type is
                            different, we customize the Lashing and Choking
                            techniques to best suit the nature of the Cargo.
                          </p>
                          <p className="__details">
                            Commonly used Lashing includes Loop Lashing, Direct
                            Lashing, Tie Down Lashing, Cross Lashing and Harness
                            Lashing. We have skilled staff having experience in
                            Lashing and Choking of various types of Cargoes
                            including Steel Coils and Metal Sheets, Automobiles,
                            Palletized/Unitized cargo, Heavy Machinery, Over
                            Dimension Cargo, Container Cargo, etc.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <section className="__accordionForMobile disableInDesktop">
          <div className="__productNameLashing"></div>
          <Container fluid className="cusFluidaccordian">
            <div className="accordion cusAccor" id="accordionExample">
              {/* FIRST */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingOne"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion"
                      type="button"
                    >
                      LASHING & CHOCKING
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="_accordionImg">
                      {/* <img src={s1} alt="" /> */}
                      <Container fluid className="__pad">
                        <div
                          id="carouselExampleControlsMob2"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <div className="carousel-inner">
                            {/* <div className="carousel-item centerAlign active">
                              {" "}
                              <img src={l1} alt="s1" className="__st" />{" "}
                            </div> */}
                            <div className="carousel-item centerAlign active">
                              {" "}
                              <img src={l2} alt="s1" className="__st" />{" "}
                            </div>
                            <div className="carousel-item centerAlign">
                              {" "}
                              <img src={l3} alt="s1" className="__st" />{" "}
                            </div>
                            <div className="carousel-item centerAlign">
                              {" "}
                              <img src={l1} alt="s1" className="__st" />{" "}
                            </div>
                          </div>
                          <div className="navigateBtn2">
                            <button
                              className="buttonGallery"
                              href="#carouselExampleControlsMob2"
                              data-slide="prev"
                            >
                              <img src={lt} className="__arrowimg" alt="" />
                            </button>
                            <button
                              className="buttonGallery"
                              href="#carouselExampleControlsMob2"
                              data-slide="next"
                            >
                              <img src={rt} className="__arrowimg" alt="" />
                            </button>
                          </div>
                        </div>
                      </Container>
                    </div>
                    <div className="accordionPara">
                      <p className="__details">
                        We have years of experience in Stuffing, Lashing and
                        Choking of Various types of Cargo in Containers as well
                        as Break Bulk Cargo. Since each Cargo type is different,
                        we customize the Lashing and Choking techniques to best
                        suit the nature of the Cargo.
                      </p>
                      <p className="__details">
                        Commonly used Lashing includes Loop Lashing, Direct
                        Lashing, Tie Down Lashing, Cross Lashing and Harness
                        Lashing. We have skilled staff having experience in
                        Lashing and Choking of various types of Cargoes
                        including Steel Coils and Metal Sheets, Automobiles,
                        Palletized/Unitized cargo, Heavy Machinery, Over
                        Dimension Cargo, Container Cargo, etc.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </section>
    );
  }
}

export default Lashing;
