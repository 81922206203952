import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../Shared/Header";
import PackGallery from "./PackGallery";
import PackFooter from "../PackFooter";

export class MainGallery extends Component {
  componentDidMount() {
    document.title = "Gallery | JCP";
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__aboutPackBG">
          <Container className="__Head">
            <Row>
              <Col md={12}>
                <h1 className="__AboutHead">Our Gallery</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <PackGallery />
        <PackFooter />
      </React.Fragment>
    );
  }
}

export default MainGallery;
