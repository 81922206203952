import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Hero from "./Components/Hero/Hero";
import Footer from "./Components/Footer/Footer";
// import About from "./Components/About/About";
// import Contact from "./Components/Contact/Contact";
import MainPagePack from "./Packaging/MainPagePack";
import MainAbout from "./Packaging/PackAbout/MainAbout";
import MainProduct from "./Packaging/PackProducts/MainProduct";
import MainContactPack from "./Packaging/PackContact/MainContactPack";
import MainGallery from "./Packaging/PackGallery/MainGallery";
import {
  Cp1,
  Cp2,
  Cp3,
  Cp4,
  Cp5,
  Cp6,
  Cp7,
  Cp8,
  Cp9,
  Ep1,
  Ep2,
  Ep3,
  Ep4,
} from "./Packaging/PackProducts/Palletsinner";
import FurnitureMain from "./Furniture/FurnitureMain";

function App() {
  useEffect(() => {
    localStorage.setItem("1", "nav-pallets-tab");
  });
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Hero />
          <Footer />
        </Route>
        <Route path="/jcp/abouts" exact>
          <MainAbout />
        </Route>
        <Route path="/jcp/contact" exact>
          <MainContactPack />
        </Route>
        <Route path="/jcp/pack" exact>
          <MainPagePack />
        </Route>
        <Route path="/jcp/abouts" exact>
          <MainAbout />
        </Route>
        <Route path="/jcp/strap" exact>
          <MainProduct />
        </Route>
        <Route path="/jcp/pallet" exact>
          <MainProduct />
        </Route>
        <Route path="/jcp/palletization" exact>
          <MainProduct />
        </Route>
        <Route path="/jcp/lashing" exact>
          <MainProduct />
        </Route>
        <Route path="/jcp/box" exact>
          <MainProduct />
        </Route>
        <Route path="/jcp/contactus" exact>
          <MainContactPack />
        </Route>
        <Route path="/jcp/gallery" exact>
          <MainGallery />
        </Route>
        <Route path="/jcp/pallet/cp1" exact>
          <Cp1 />
        </Route>
        <Route path="/jcp/pallet/cp2" exact>
          <Cp2 />
        </Route>
        <Route path="/jcp/pallet/cp3" exact>
          <Cp3 />
        </Route>
        <Route path="/jcp/pallet/cp4" exact>
          <Cp4 />
        </Route>
        <Route path="/jcp/pallet/cp5" exact>
          <Cp5 />
        </Route>
        <Route path="/jcp/pallet/cp6" exact>
          <Cp6 />
        </Route>
        <Route path="/jcp/pallet/cp7" exact>
          <Cp7 />
        </Route>
        <Route path="/jcp/pallet/cp8" exact>
          <Cp8 />
        </Route>
        <Route path="/jcp/pallet/cp9" exact>
          <Cp9 />
        </Route>
        <Route path="/jcp/pallet/ep1" exact>
          <Ep1 />
        </Route>
        <Route path="/jcp/pallet/ep2" exact>
          <Ep2 />
        </Route>
        <Route path="/jcp/pallet/ep3" exact>
          <Ep3 />
        </Route>
        <Route path="/jcp/pallet/ep4" exact>
          <Ep4 />
        </Route>
        <Route path="/jcp/furniture" exact>
          <FurnitureMain/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
