import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import BG from "../Packaging/assets/Images/Path.png";
import brandLogo from "../Packaging/assets/Images/logo.png";
import down from "../Packaging/assets/Images/down.png";
import menu from "../Packaging/assets/Images/menu.png";
import Button from "./Button";
import dp from "../Packaging/assets/Images/dp.png";

export class Header extends Component {
  componentDidMount() {
    document.body.style.backgroundImage = `url(${BG})`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";
    window.addEventListener("scroll", this.handleScroll);
    let CurrentPath = window.location.pathname;
    if (CurrentPath === "/jcp/pack") {
      this.setState({
        activeHome: "active",
        activeAbout: "",
      });
    }
    if (CurrentPath === "/jcp/abouts") {
      this.setState({
        activeHome: "",
        activeAbout: "active",
      });
    }
    if (
      CurrentPath === "/jcp/pallet" ||
      CurrentPath === "/jcp/palletization" ||
      CurrentPath === "/jcp/lashing" ||
      CurrentPath === "/jcp/strap" ||
      CurrentPath === "/jcp/box"
    ) {
      this.setState({
        activeHome: "",
        activeAbout: "",
        activeProduct: "active",
      });
    }
    if (CurrentPath === "/jcp/contactus") {
      this.setState({
        activeHome: "",
        activeAbout: "",
        activeProduct: "",
        activeContactus: "active",
      });
    }
    if (CurrentPath === "/jcp/gallery") {
      this.setState({
        activeHome: "",
        activeAbout: "",
        activeProduct: "",
        activeContactus: "",
        activeGallery: "active",
      });
    }
    if (
      CurrentPath === "/jcp/pallet/cp1" ||
      CurrentPath === "/jcp/pallet/cp2" ||
      CurrentPath === "/jcp/pallet/cp3" ||
      CurrentPath === "/jcp/pallet/cp4" ||
      CurrentPath === "/jcp/pallet/cp5" ||
      CurrentPath === "/jcp/pallet/cp6" ||
      CurrentPath === "/jcp/pallet/cp7" ||
      CurrentPath === "/jcp/pallet/cp8" ||
      CurrentPath === "/jcp/pallet/cp9" ||
      CurrentPath === "/jcp/pallet/ep1" ||
      CurrentPath === "/jcp/pallet/ep2" ||
      CurrentPath === "/jcp/pallet/ep3" ||
      CurrentPath === "/jcp/pallet/ep4"
    ) {
      this.setState({
        activeHome: "",
        activeAbout: "",
        activeProduct: "active",
      });
    }
    if (CurrentPath === "/jcp/furniture") {
      this.setState({
        activeHome: "",
        activeAbout: "",
        activeProduct: "",
        activeBusiness: "active",
        activeContactus: "",
        activeGallery: "",
      });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      css: "__mt-top",
      aqcss: "",
      activeHome: "",
      activeAbout: "active",
      activeProduct: "",
      activeBusiness: "",
      activeGallery: "",
      activeContactus: "",
      isopen: false,
      isproductopen: false,
      isbusinessopen: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleproduct = this.handleproduct.bind(this);
    this.handlebusiness = this.handlebusiness.bind(this);
  }
  handleScroll() {
    if (window.scrollY > 50) {
      console.log("should lock");
      this.setState({
        css: "test __color2",
        aqcss: "__pt-195",
      });
    } else if (window.scrollY < 50) {
      console.log("not locked");
      this.setState({
        css: "__mt-top",
        aqcss: "",
      });
    }
  }

  handleMenu() {
    this.setState({
      isopen: !this.state.isopen,
    });
  }
  handleproduct() {
    this.setState({
      isproductopen: !this.state.isproductopen,
    });
  }
  handlebusiness() {
    this.setState({
      isbusinessopen: !this.state.isbusinessopen,
    });
  }
  render() {
    return (
      <React.Fragment>
        <header className="__pos __positionMobile">
          <Container className={this.state.css}>
            <Row className="__color">
              <Col md={12}>
                <Row>
                  <Col md={3} className="stickImg center">
                    <Link to="/">
                      <img
                        src={brandLogo}
                        alt="logo"
                        className="logo-img-pack"
                      />
                    </Link>
                  </Col>
                  <Col md={9} className="stickNav headSus">
                    <nav className="__nav">
                      <ul className="__ul">
                        <li className="__li">
                          <Link
                            to="/jcp/pack"
                            exact
                            className={this.state.activeHome}
                          >
                            HOME
                          </Link>
                        </li>
                        <li className="__li">
                          <Link
                            to="/jcp/abouts"
                            exact
                            className={this.state.activeAbout}
                          >
                            ABOUT
                          </Link>
                        </li>
                        <li className="__li dp">
                          <a
                            href=""
                            className={`d ${this.state.activeProduct}`}
                          >
                            PRODUCTS OFFERED
                            <img
                              src={dp}
                              className="__dp "
                              //
                            />
                          </a>
                          <ul className="vs">
                            <Link to="/jcp/pallet">PALLETS</Link>
                            <Link to="/jcp/palletization">PALLETIZATION</Link>
                            <a href="/jcp/lashing">LASHING/CHOCKING</a>
                            <Link to="/jcp/strap" exact>
                              STRAPPING SOLUTION
                            </Link>

                            <Link to="/jcp/box">BOX & CRATES</Link>
                          </ul>
                        </li>

                        <li className="__li dp">
                          <a
                            href="#"
                            className={`d ${this.state.activeBusiness}`}
                          >
                            BUSINESSES <img src={dp} className="__dp" />
                          </a>
                          <ul className="vs secondryudropdown">
                            <Link to="/jcp/pack" className="businessAtag">
                              PACKAGING SOLUTION
                            </Link>

                            <Link to="/jcp/furniture" className="businessAtag">
                              FURNITURE SOLUTION
                            </Link>
                          </ul>
                        </li>
                        <li className="__li">
                          <Link
                            to="/jcp/gallery"
                            exact
                            className={this.state.activeGallery}
                          >
                            GALLERY
                          </Link>
                        </li>
                        <li className="__li">
                          <Link
                            to="/jcp/contactus"
                            exact
                            className={this.state.activeContactus}
                          >
                            CONTACT
                          </Link>
                        </li>
                        {/* <li className="__li __forBtn">
                          <Button
                            title="BROCHURE"
                            btnCSS="button"
                            img={down}
                            alt="icon"
                            iconDes="downIcon"
                          />
                        </li> */}
                      </ul>
                    </nav>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Container className="__mobileHeader">
            <Row>
              <Col md={3} className="stickImg cus-col-md-3">
                <Link to="/">
                  <img
                    src={brandLogo}
                    alt="logo"
                    className="logo-img-pack-mobile"
                  />
                </Link>
              </Col>
              <Col md={9} sm={9} className="cus-col-md-9">
                <div className="insideBurgerMenu">
                  <span onClick={this.handleMenu}>
                    MENU
                  </span>
                  <img
                    src={menu}
                    alt="logo"
                    className="menu"
                    onClick={this.handleMenu}
                  />
                </div>
              </Col>
              <Col
                md={12}
                className={`mobNav ${this.state.isopen ? "showmobnav" : ""}`}
              >
                <ul>
                  {/* <li> */}
                  <Link to="/jcp/pack" exact className={this.state.activeHome}>
                    HOME
                  </Link>
                  {/* </li> */}
                  {/* <li> */}
                  <Link
                    to="/jcp/abouts"
                    exact
                    className={this.state.activeAbout}
                  >
                    ABOUT
                  </Link>
                  {/* </li> */}
                  <li
                    className={`poMob ${
                      this.state.isproductopen ? "showpomob" : ""
                    }`}
                    onClick={this.handleproduct}
                  >
                    <a href="#" className={`po ${this.state.activeProduct}`}>
                      PRODUCT OFFERED <img src={dp} className="__dp" />
                    </a>
                    <ul>
                      <Link to="/jcp/pallet" onClick={this.handleMenu}>
                        PALLETS
                      </Link>
                      <Link to="/jcp/palletization" onClick={this.handleMenu}>
                        PALLETIZATION
                      </Link>

                      <a href="/jcp/lashing" onClick={this.handleMenu}>
                        LASHING/CHOCKING
                      </a>

                      <Link to="/jcp/strap" onClick={this.handleMenu}>
                        STRAPPING SOLUTION
                      </Link>

                      <Link to="/jcp/box" onClick={this.handleMenu}>
                        BOX & CRATES
                      </Link>
                    </ul>
                  </li>
                  <li
                    className={`busMob ${
                      this.state.isbusinessopen ? "showbusmob" : ""
                    }`}
                    onClick={this.handlebusiness}
                  >
                    <a href="#">
                      BUSINESSES <img src={dp} className="__dp" />
                    </a>
                    <ul>
                      <Link to="/jcp/pack">PACKAGING SOULUTION</Link>
                      <Link to="/jcp/furniture">FURNITURE SOLUTION</Link>
                    </ul>
                  </li>
                  {/* <li> */}
                  <Link
                    to="/jcp/gallery"
                    exact
                    className={this.state.activeGallery}
                  >
                    GALLERY
                  </Link>
                  {/* </li> */}
                  {/* <li> */}
                  <Link
                    to="/jcp/contactus"
                    exact
                    className={this.state.activeContactus}
                  >
                    CONTACT
                  </Link>
                  {/* </li> */}
                </ul>
              </Col>
            </Row>
          </Container>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
