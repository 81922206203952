import React, { Component } from "react";

export class Button extends Component {
  render() {
    return (
      <a href={this.props.goto} className={this.props.btnCSS}>
        <img
          src={this.props.img}
          alt={this.props.alt}
          className={this.props.iconDes}
        />
        {this.props.title}
      </a>
    );
  }
}

export default Button;
