import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import company from "../assets/Images/company.png";
import branch from "../assets/Images/branch.png";
import Bt from "../../Shared/Button";
import pin from "../assets/Images/pin.png";

export class PackContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobNum: "",
      location: "",
      message: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handlesubmit(e) {
    e.preventDefault();
    const data = JSON.stringify(this.state);
    const apiUrl = "https://jayantcargopackers.com/jcpmail/mail";
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      body: data,
      myHeaders,
    };
    fetch(apiUrl, options)
      .then((res) => res.text())
      .then(
        (result) => {
          alert("Mail sent");
        },
        (error) => {
          console.log(error);
        }
      );
    this.setState({
      name: "",
      email: "",
      mobNum: "",
      location: "",
      message: "",
    });
  }
  render() {
    return (
      <section className="__contactFormSection">
        <Container>
          <Row>
            <Col md={12} lg={6}>
              <div className="__loveTohearDiv">
                <p className="__loveTohear">We’d love to hear from you</p>
              </div>
              <div className="__contactreqDiv">
                <p className="__contactreq">
                  If you have Queries, regarding any packaging solutions,
                  quality, and specification our Professional Team is ready to
                  help.
                </p>
              </div>
              <div className="__companyDiv">
                <Container fluid className="__conFuild">
                  <Row>
                    <Col md={4} className="__alignRight cusCol4">
                      <img src={company} alt="" className="__companyImg" />
                    </Col>
                    <Col md={8} className="cusCol8">
                      <div className="__officeAdd">OFFICE ADDRESS</div>
                      <div className="__address">
                        PLOT NO. 161, G.I.D.C INDUSTRIAL AREA, NEAR OSLO CINEMA,
                        GANDHIDHAM KUTCH 370 201
                      </div>
                    </Col>
                  </Row>
                </Container>
                <div className="__locateUsDiv">
                  <Bt
                    title="LOCATE US"
                    btnCSS="__locateUs"
                    img={pin}
                    alt=""
                    iconDes="__pin"
                    goto="https://www.google.com/maps/place/23%C2%B003'31.7%22N+70%C2%B007'58.5%22E/@23.0587959,70.1307154,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d23.0587959!4d70.1329041?hl=en"
                  />
                </div>
              </div>

              <div className="__companyDiv">
                <Container fluid className="__conFuild">
                  <Row>
                    <Col md={4} className="__alignRight cusCol4">
                      <img src={branch} alt="" className="__companyImg" />
                    </Col>
                    <Col md={8} className="cusCol8">
                      <div className="__officeAdd">FACTORY ADDRESS</div>
                      <div className="__address">
                        PLOT NO. 53, G.G.D.C INDUSTRIAL ESTATE, MITHIROHAR
                        G.I.D.C AREA, GANDHIDHAM KUTCH 370240
                      </div>
                    </Col>
                  </Row>
                </Container>
                <div className="__locateUsDiv">
                  <Bt
                    title="LOCATE US"
                    btnCSS="__locateUs"
                    img={pin}
                    alt=""
                    iconDes="__pin"
                    goto="https://www.google.com/maps/place/23%C2%B005'33.8%22N+70%C2%B010'29.9%22E/@23.0927219,70.1727686,686m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d23.0927219!4d70.1749573?hl=en"
                  />
                </div>
              </div>
            </Col>
            <Col md={12} lg={6} className="__contactForm">
              <div>
                <Form onSubmit={this.handlesubmit.bind(this)}>
                  <Form.Group
                    controlId="formBasicName"
                    className="__cusFormGroup"
                  >
                    <Form.Control
                      type="text"
                      placeholder="NAME"
                      className="__cusFormControl"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicEmail"
                    className="__cusFormGroup"
                  >
                    <Form.Control
                      type="email"
                      placeholder="EMAIL"
                      className="__cusFormControl"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicMobile"
                    className="__cusFormGroup"
                  >
                    <Form.Control
                      type="tel"
                      placeholder="MOBILE NO."
                      className="__cusFormControl"
                      name="mobNum"
                      value={this.state.mobNum}
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicLocation"
                    className="__cusFormGroup"
                  >
                    <Form.Control
                      type="text"
                      placeholder="LOCATION"
                      className="__cusFormControl"
                      name="location"
                      value={this.state.location}
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicMessage"
                    className="__cusFormGroup"
                  >
                    <Form.Control
                      as="textarea"
                      aria-label="With textarea"
                      className="__cusFormControl"
                      placeholder="MESSAGE"
                      name="message"
                      value={this.state.message}
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Group>
                  <button type="submit" className="__SubmitButton">
                    submit
                  </button>
                  {/* <Button2 title="SUBMIT" btnCSS="" /> */}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default PackContact;
