import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

// import prev from "../assets/Images/prev.png";
// import next from "../assets/Images/next.png";
// import s1 from "../assets/Images/strapMain.png";

import w1 from "../assets/Images/box/b1.png";
// import w2 from "../assets/Images/box/b2.png";
// import w3 from "../assets/Images/box/b3.png";
// import w4 from "../assets/Images/box/b4.png";
// import w5 from "../assets/Images/box/b5.png";

// import wb1 from "../assets/Images/box/wb1.png";
// import wb2 from "../assets/Images/box/wb2.png";
// import wb3 from "../assets/Images/box/wb3.png";
// import wb4 from "../assets/Images/box/wb4.png";
// import wb5 from "../assets/Images/box/wb5.png";
import dp from "../assets/Images/dp.png";

export class Box extends Component {
  componentDidMount() {
    document.title = "Wooden box and crates for cargo | JCP";
  }
  render() {
    return (
      <section className="__mtbot">
        <Container fluid className="__productPos">
          <Row>
            <Col md={12}>
              <div className="__productNameBox"></div>
            </Col>
            <Col md={12} className=" __cros">
              <Container fluid className="__mTop">
                <Row>
                  <Col md={1} className="__endPrev">
                    {/* <a href="#carouselExampleIndicators" data-slide="prev">
                      <img src={prev} alt="prev" className="__prevImg" />
                    </a> */}
                  </Col>
                  <Col md={10}>
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <nav className="__navProduct __cusProduct">
                            <div
                              className="nav nav-tabs __nav-tab navtab"
                              id="nav-tab"
                              role="tablist"
                            >
                              <a
                                className="nav-item nav-link __nav-link navlink active"
                                id="nav-pallets-tab"
                                data-toggle="tab"
                                href="#nav-pallets"
                                role="tab"
                                aria-controls="nav-pallets"
                                aria-selected="true"
                              >
                                WOODEN BOX AND CRATES
                              </a>
                              {/* <a
                                className="nav-item nav-link __nav-link navlink"
                                id="nav-PALLETIZATION-tab"
                                data-toggle="tab"
                                href="#nav-PALLETIZATION"
                                role="tab"
                                aria-controls="nav-PALLETIZATION"
                                aria-selected="false"
                              >
                                WOODEN BOX AND CRATE
                              </a> */}
                            </div>
                          </nav>
                        </div>
                        {/* Paste ur code here */}
                      </div>
                    </div>
                  </Col>
                  <Col md={1} className="__endNext">
                    {/* <a href="#carouselExampleIndicators" data-slide="next">
                      <img src={next} alt="next" className="__nextImg" />
                    </a> */}
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={12} className="__pad">
              <div className="tab-content" id="nav-tabContent">
                {/* ONE */}
                <img className="d-block w-100 bgBlack" src={w1} alt="First slide" />
                {/* <div
                  className="tab-pane fade show active __mtTop40"
                  id="nav-pallets"
                  role="tabpanel"
                  aria-labelledby="nav-pallets-tab"
                >
                  <Container fluid className="__pad">
                    <div
                      id="carouselExampleControls"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img
                            className="d-block w-100"
                            src={w1}
                            alt="First slide"
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            className="d-block w-100"
                            src={w2}
                            alt="Second slide"
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            className="d-block w-100"
                            src={w3}
                            alt="Third slide"
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            className="d-block w-100"
                            src={w4}
                            alt="Second slide"
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            className="d-block w-100"
                            src={w5}
                            alt="Third slide"
                          />
                        </div>
                      </div>
                      <a
                        className="carousel-control-prev"
                        href="#carouselExampleControls"
                        role="button"
                        data-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next"
                        href="#carouselExampleControls"
                        role="button"
                        data-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </Container>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
        <section className="__accordionForMobile disableInDesktop">
          <div className="__productNameBox"></div>
          <Container fluid className="cusFluidaccordian">
            <div className="accordion cusAccor" id="accordionExample">
              {/* FIRST */}
              <div className="card cusCard">
                <div
                  className="card-header cusCardHead"
                  id="headingOne"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style={{ zIndex: "99" }}
                >
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link cusBtnAccordion"
                      type="button"
                    >
                      WOODEN BOX
                    </button>
                    <img src={dp} alt="" className="arrowDown" />
                  </h2>
                </div>
                <img className="d-block w-100" src={w1} alt="First slide" />
              </div>
            </div>
          </Container>
        </section>
      </section>
    );
  }
}

export default Box;
