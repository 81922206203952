import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// Image
import mail from "./assets/mail.png";
import phone from "./assets/phone.png";
import fb from "./assets/fb.png";
import youtube from "./assets/youtube.png";

class FurnitureFooter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container style={{ marginBottom: "20px" }} className="disableInMobile">
          <hr />
          <Row>
            <Col md={3} className="lineDiv1">
              <div className="iconsFooter ">
                <span>
                  <a href="mailto:jaynatgim@gmail.com">
                    <img src={mail} alt="" />
                  </a>
                </span>
                <span>
                  <a href="tel: +91 9712980621">
                    <img src={phone} alt="" />
                  </a>
                </span>
              </div>
            </Col>
            <Col md={6} className="lineDiv2 copyRyt">
              Copyright @ All Rights reserved - Designed & Maintained by
              <span>
                <a href="https://www.beakmedia.com/" className="beak">
                  {" "}
                  Beak Media
                </a>
              </span>
            </Col>
            <Col md={3}>
              <div className="iconsFooter">
                <span>
                  <img src={fb} alt="" />
                </span>
                <span>
                  <img src={youtube} alt="" />
                </span>
              </div>
            </Col>
          </Row>
        </Container>
        {/* Footer In Mobile */}
        <Container
          style={{ marginBottom: "20px" }}
          className="disableInDeskTop"
        >
          <hr />
          <Row>
            <Col className="lineDiv1 md3">
              <div className="iconsFooter ">
                <span>
                  <a href="mailto:jaynatgim@gmail.com">
                    <img src={mail} alt="" />
                  </a>
                </span>
                <span>
                  <a href="tel: +91 9712980621">
                    <img src={phone} alt="" />
                  </a>
                </span>
              </div>
            </Col>
            {/* <Col className="md3">
            <div className="iconsFooter">
              <span>
                <img src={fb} alt="" />
              </span>
              <span>
                <img src={youtube} alt="" />
              </span>
            </div>
          </Col> */}
            <Col className="lineDiv2 copyRyt mdfooter6">
              Copyright @ All Rights reserved - Designed & Maintained by
              <span>
                <a href="https://www.beakmedia.com/" className="beak">
                  {" "}
                  Beak Media
                </a>
              </span>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default FurnitureFooter;
