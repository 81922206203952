import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ab from "../assets/Images/ab.png";
import about from "../assets/Images/about.png";

export class PackAbout extends Component {
  render() {
    return (
      <Container fluid>
        <section className="__ptBT">
          <Container fluid className="__mainContainer">
            <Row>
              <Col md={5}>
                <div>
                  <img src={ab} className="__abImg" alt="" />
                </div>
              </Col>
              <Col md={7} className="__poss _paraDiv">
                <div>
                  <p className="__welcomeJCP">WELCOME TO JCP</p>
                </div>
                <div>
                  <h1 className="__serviceText">
                    WE ARE SUPPLIERS, PACKERS & PALLETIZERS OF EXPORT CARGO
                  </h1>
                </div>
                <div className="__aboutParaDiv">
                  <div className="__aboutPara">
                    <span className="bold">Jayant packing Industries</span> was
                    Established in 1966 as{" "}
                    <span className="bold">Ship Store Suppliers</span> and{" "}
                    <span className="bold">Packers</span>. We are{" "}
                    <span className="bold">
                      Pioneer in Palletization of Export Cargo
                    </span>
                    .
                  </div>
                  <div className="__aboutPara">
                    We have Expert Professionally Skilled Staff to Manage and
                    Execute Complete{" "}
                    <span className="bold">
                      Palletizing, Wooden Industrial Packing, and Wooden Crating
                      of Export Cargo
                    </span>
                    .{" "}
                    <span className="bold">
                      We are ISPM-15 Certified for Heat Treatment.
                    </span>{" "}
                    Exporters have used our Pallets to send Cargo to Australia,
                    New Zealand, U.S.A and many more Countries around the World.
                  </div>
                </div>
                <div className="__achive">
                  <div className="__num">55+</div>
                  <div className="__exp">YEARS OF PACKAGING EXPERIENCE</div>
                </div>
              </Col>
            </Row>
          </Container>
          {/* FOR MOBILE VIEW */}
          <Container fluid className="__aboutMobile">
            <Row>
              <Col md={12}>
                <img src={about} className="__aboutImgMobile" alt="" />
              </Col>
              <Col md={12} className="aboutparamobile">
                <div>
                  <h1 className="__serviceText">
                    WE ARE SUPPLIERS, PACKERS & PALLETIZERS OF EXPORT CARGO
                  </h1>
                </div>
                <p>
                  <span className="bold">Jayant packing Industries</span> was
                  Established in 1966 as{" "}
                  <span className="bold">Ship Store Suppliers</span> and{" "}
                  <span className="bold">Packers</span>. We are{" "}
                  <span className="bold">
                    Pioneer in Palletization of Export Cargo
                  </span>
                  .
                </p>
                <p>
                  We have Expert Professionally Skilled Staff to Manage and
                  Execute Complete{" "}
                  <span className="bold">
                    Palletizing, Wooden Industrial Packing, and Wooden Crating
                    of Export Cargo
                  </span>
                  .{" "}
                  <span className="bold">
                    {/* We Certified from Government of India For Providing for Heat
                    Treatment Service as Per ISPM # 15 Norms. Our License No.
                    IN-113/HT */}
                    We are ISPM-15 Certified for Heat Treatment.
                  </span>{" "}
                  {/* Indian Exporters are using our Pallets to Export Their Cargo
                  to Australia, New Zealand, U.S.A and many more Countries
                  around the World. */}
                  Exporters have used our Pallets to send Cargo to Australia,
                  New Zealand, U.S.A and many more Countries around the World.
                </p>
                {/* <p>We are providing services such as:</p> */}
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    );
  }
}

export default PackAbout;
