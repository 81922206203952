import React, { Component } from "react";

export class Button2 extends Component {
  render() {
    return (
      <a href={this.props.whereto} className={this.props.btnCSS}>
        {this.props.title}
        <img
          src={this.props.img}
          alt={this.props.alt}
          className={this.props.iconDes}
        />
      </a>
    );
  }
}

export default Button2;
