import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import c1_1 from "../assets/Images/gallery/c1_1.png";
import c1_2 from "../assets/Images/gallery/c1_2.png";
import c1_3 from "../assets/Images/gallery/c1_3.png";
import c1_4 from "../assets/Images/gallery/c1_4.png";
import c1_5 from "../assets/Images/gallery/c1_5.png";
import c1_6 from "../assets/Images/gallery/c1_6.png";

import c2_1 from "../assets/Images/gallery/c2_1.png";
import c2_2 from "../assets/Images/gallery/c2_2.png";
import c2_3 from "../assets/Images/gallery/c2_3.png";
import c2_4 from "../assets/Images/gallery/c2_4.png";
import c2_5 from "../assets/Images/gallery/c2_5.png";

import c3_1 from "../assets/Images/gallery/c3_1.png";
import c3_2 from "../assets/Images/gallery/c3_2.png";

import c4_1 from "../assets/Images/gallery/c4_1.png";
import c4_2 from "../assets/Images/gallery/c4_2.png";
import c4_3 from "../assets/Images/gallery/c4_3.png";

export class PackGallery extends Component {
  render() {
    return (
      <section className="__cusContainerFromGallery">
        <Container fluid>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active __mtTop40"
              id="nav-office"
              role="tabpanel"
              aria-labelledby="nav-office-tab"
            >
              <div className="__cusRowInGallery">
                <div className="__column">
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c1_1} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c1_2} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c1_3} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  {/* <div className="__card">
                    <div className="__cardBody">
                      <img src={c1_4} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c1_5} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c1_6} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div> */}
                </div>
                {/* Column 2 */}
                <div className="__column">
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c2_1} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c2_2} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c2_3} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  {/* <div className="__card">
                    <div className="__cardBody">
                      <img src={c2_4} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c2_5} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div> */}
                </div>
                {/* Column 3 */}
                <div className="__column">
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c3_1} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c3_2} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                </div>
                {/* Column 4 */}
                <div className="__column">
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c4_1} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c4_2} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                  <div className="__card">
                    <div className="__cardBody">
                      <img src={c4_3} alt="" />
                    </div>
                    <div className="__cardTitle">
                      <p>JCP Gujarat Office</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    );
  }
}

export default PackGallery;
