import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../Shared/Header";
import Button2 from "../../Shared/Button2";
import crt from "../assets/Images/caretRT.png";

export class Head extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="__pack-hero-bg">
          <Container className="__packSolution">
            <Row>
              <Col md={12}>
                <div className="__square">
                  <span>Complete Packaging Solution</span>
                </div>
                <Button2
                  title="Scroll Below"
                  btnCSS="__GetStarted"
                  alt="icon"
                  img={crt}
                  iconDes="__crtGetStarted"
                />
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Head;
