import React from "react";
import "./Hero.css";
import Header from "../Home/Header";
import { Container, Row, Col } from "react-bootstrap";
import brandLogo from "../assets/Images/logo.png";
import MiddleSection from "../MiddleSection/MiddleSection";

function Hero() {
  return (
    <div className="hero-bg">
      <Header />
      <Container fluid className="herodisable">
        <Row>
          <Col md={4} sm={4}>
            <div className="logo-div">
              <img src={brandLogo} alt="logo" className="logo-img" />
            </div>
          </Col>
          <Col md={7} sm={7} className="div-align">
            <div className="rectangle">
              <p className="welcome">WELCOME TO</p>
            </div>
            <div className="rectangle_2">
              <p className="name">JAYANT CARGO PACKERS</p>
            </div>
            <div className="rectangle_3">
              <p className="work">AS PER ISPM - 15 STANDARD</p>
            </div>
          </Col>
        </Row>
      </Container>
      <MiddleSection />
    </div>
  );
}

export default Hero;
